import { Injectable } from '@angular/core';
import { API_LIST } from '@core/services/web-api/api-list';
import { HttpClient } from '@angular/common/http';
import {Observable, of, BehaviorSubject} from 'rxjs';
import {Country, GeoCountry, GeoData, UK_COUNTRIES} from '@core/models/country.model';
import {share} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CountriesService {
  private localStorageName = 'geodata';
  private refreshGeodataEverySeconds = 1800;
  private countries: Country[] | null = null;
  private countries$: Observable<Country[]> | null = null;
  public visitorCountry$: BehaviorSubject<GeoData|null> = new BehaviorSubject(null);
  private isRequestingVisitorCountry = false;
  private forcedGeo: any = null;

  constructor(private httpClient: HttpClient) {
  }

  getCountries(): Observable<Country[]> {
    if (this.countries && this.countries.length) {
      return of(this.countries);
    }

    if (!this.countries$) {
      this.countries$ = this.httpClient.get<Country[]>(API_LIST.countries()).pipe(share());
    }

    return this.countries$;
  }

  indexOfCountryId(id, countries: Country[]): number {
    let index = -1;
    countries.forEach((country, i) => {
      if (id === country.id) {
        index = i;
      }
    });

    return index;
  }

  getGeoVersionByCountryIso(countryIso: string, route: ActivatedRoute|null = null): GeoCountry {
    if (route) {
      const forcedGeo = route.snapshot.queryParamMap.get('geo');

      if (forcedGeo) {
        this.forcedGeo = forcedGeo;
      }
    }

    if (this.forcedGeo == 'none') {
      return '';
    } else if (this.forcedGeo) {
      return <GeoCountry> this.forcedGeo;
    }

    return UK_COUNTRIES.indexOf(countryIso) >= 0 ? 'uk' : 'world';
  }

  detectGeoCountryIfExpired() {
    const geoDataString = localStorage.getItem(this.localStorageName);

    if (!geoDataString) {
      this.detectGeoCountry();
      return;
    }

    const geoData: GeoData|null = <GeoData|null> JSON.parse(geoDataString);
    const currentTime = (new Date()).getTime() / 1000;

    if (geoData) {
      this.visitorCountry$.next(geoData);
    }

    if (!geoData || currentTime - geoData.time > this.refreshGeodataEverySeconds) {
      this.detectGeoCountry();
      return;
    }
  }

  detectGeoCountry() {
    if (this.isRequestingVisitorCountry) {
      return;
    }

    this.isRequestingVisitorCountry = true;

    this.httpClient.post<GeoData>(API_LIST.postGeoData(), {}).subscribe((geoData: GeoData) => {
      geoData.time = Math.floor((new Date()).getTime() / 1000); // to use local time
      const geoDataString = JSON.stringify(geoData);
      localStorage.setItem(this.localStorageName, geoDataString);

      this.visitorCountry$.next(geoData);
      this.isRequestingVisitorCountry = false;
    }, () => {
      this.isRequestingVisitorCountry = false;
    });
  }
}
