import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafePlayControlDialogComponent } from '@components/dialog/safe-play-control-dialog/safe-play-control-dialog.component';
import {
  ScheduledMessage,
  ScheduledMessageType,
  User
} from '@core/models/user.model';
import { Select, Store } from '@ngxs/store';
import { RefreshUser } from '@store/auth/auth.actions';
import { AuthState } from '@store/auth/auth.state';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SafePlayService implements OnDestroy {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.user) user$: Observable<User>;

  private destroyed$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleScheduledMessages(learnMoreBtn: string, redirectUrl: string): void {
    timer(0, 60000)
      .pipe(
        switchMap(() => this.isAuthenticated$),
        switchMap(isAuthenticated =>
          isAuthenticated ? this.userService.getScheduledMessages() : of([])
        ),
        switchMap(scheduledMessages => {
          const isSpendMessage =
            scheduledMessages[0]?.type === ScheduledMessageType.Spend;
          const currentMonthlySpend$ = isSpendMessage
            ? this.userService.getCurrentMonthlySpend()
            : of(null);
          const userInfo$ = isSpendMessage
            ? this.store
                .dispatch(new RefreshUser())
                .pipe(map(session => session?.auth?.user))
            : this.user$;
          return combineLatest([
            of(scheduledMessages),
            userInfo$,
            currentMonthlySpend$
          ]);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(([scheduledMessages, user, currentMonthlySpend]) => {
        if (
          scheduledMessages.length > 0 &&
          this.dialog.openDialogs.length === 0
        ) {
          this.openSafePlayControlDialogComponent(
            scheduledMessages[0],
            learnMoreBtn,
            redirectUrl,
            user.monthly_spend_limit,
            currentMonthlySpend?.monthly_spend
          );
        }
      });
  }

  private openSafePlayControlDialogComponent(
    scheduledMessage: ScheduledMessage,
    learnMoreBtn: string,
    redirectUrl: string,
    monthlySpendLimit: number,
    monthlySpend: number
  ): void {
    this.dialog
      .open(SafePlayControlDialogComponent, {
        disableClose: true,
        backdropClass: 'app-overlay-backdrop',
        panelClass: 'safe-play-control-dialog',
        data: {
          scheduledMessage,
          learnMoreBtn,
          redirectUrl,
          monthlySpendLimit,
          monthlySpend
        }
      })
      .afterClosed()
      .pipe(
        switchMap(slug =>
          slug ? this.userService.patchScheduledMessages(slug) : of(null)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }
}
