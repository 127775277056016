import { MediaType } from './media-info.model';

export class HomePageSlide {
  title: string;
  line1: string;
  line2: string;
  sourceUrl: string;
  poster?: string;
  type: MediaType;
  ctaUrl: string;
  ctaTitle?: string;
  duration?: number;
  isSourceLoaded? = false;
  isSourceLoadedPhase2? = false;
}
