import {
  CurrencyPipe,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase
} from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CountdownComponent } from '@components/countdown/countdown.component';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import {
  DrawAvailability,
  Raffle,
  RaffleSimple
} from '@core/models/raffle.model';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { RaffleCardBadgeComponent } from '../raffle-card-badge/raffle-card-badge.component';

@Component({
  selector: 'app-raffle-card[raffle]',
  templateUrl: './raffle-card.component.html',
  styleUrls: ['./raffle-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgStyle,
    NgSwitchCase,
    CurrencyPipe,
    RouterModule,
    SvgIconComponent,
    RaffleCardBadgeComponent,
    ReactiveFormsModule,
    FormsModule,
    CountdownComponent
  ]
})
export class RaffleCardComponent implements OnChanges {
  @Input() raffle: RaffleSimple | null;
  @Input() version: 'white' | null = null;

  public price: string;
  public originalPrice: string;
  public currency: string;
  public min = 0;
  public sliderMaxTickets = 100;
  public sliderValue: number;
  public sliderHalfOfValue = 50;
  public drawTimeVersion = '';
  public isInApp = false;
  public isMixed: boolean;
  public bottomInfoText = '';

  constructor() {
    this.isInApp = GonativeService.isGonative();
  }

  get isInstantWin(): boolean {
    return (
      this.raffle.instant_win_count > 0 &&
      this.raffle.category_ids.includes('instant-wins')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.raffle.currentValue) {
      const raffle: Raffle = changes.raffle.currentValue as Raffle;
      this.price = raffle.offers[0].price;
      this.originalPrice = raffle.offers[0].original_price_value_frontend;
      this.currency = raffle.offers[0].currency;
      this.sliderValue = Math.floor(
        (raffle.current_entries / raffle.max_entries) * 100
      );

      if (
        this.raffle.purchase_available_type === DrawAvailability.AppOnly &&
        !this.isInApp
      ) {
        this.bottomInfoText = 'App Exclusive';
      } else if (
        this.raffle.purchase_available_type === DrawAvailability.BrowserOnly &&
        this.isInApp
      ) {
        this.bottomInfoText = 'Only in browser';
      } else if (
        !this.raffle.is_without_draw &&
        this.raffle.draw_method === 'auto'
      ) {
        this.bottomInfoText = 'Automated Draw';
      }
    }
  }
}
