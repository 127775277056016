import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {SettingsState, SettingsStateModel} from '@store/settings/settings.state';
import {SettingsModel} from '@core/models/settings.model';
import {API_LIST} from '@core/services/web-api/api-list';
import {first, share} from 'rxjs/operators';
import {SetSettingsData} from '@store/settings/settings.actions';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  getRequest$: Observable<SettingsModel>|null;

  @Select(SettingsState.state) state$: Observable<SettingsStateModel>;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {
    this.state$.subscribe((state) => {
      if (!state || !state.isInitiated) {
        return;
      }

      this.refreshSettings();
    });
  }

  refreshSettings() {
    if (!this.getRequest$) {
      this.getRequest$ = this.httpClient.get<SettingsModel>(API_LIST.getSettings()).pipe(
        share()
      );

      this.getRequest$.pipe(first()).subscribe((settingsData: SettingsModel) => {
        this.store.dispatch(new SetSettingsData(settingsData));

        setTimeout(() => {
          this.getRequest$ = null;
        }, 10);
      });
    }

    return this.getRequest$;
  }
}
