<div class="email-prompt">
  <input type="text" class="email-input" title="Email Address" placeholder="{{ placeholder }}" name="email" inputmode="email"
    [(ngModel)]="emailAddress" [ngClass]="{'email-input_disabled': isSubmitting}">

  <button class="button-new button-new__primary button-new__small" [attr.disabled]="isSubmitting ? 'disabled': null" (click)="submitEmail()">{{ submitName }}</button>
</div>

<div class="form-errors">
  <div class="form-error" *ngFor="let error of errors">
    {{error}}
  </div>
</div>
