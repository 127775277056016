import { Injectable } from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';
import {HttpClient} from '@angular/common/http';
import {API_LIST} from '@core/services/web-api/api-list';
import { ProjectDataService } from '../project-data/project-data.service';

export class OneSignalInfo {
  // https://gonative.readme.io/docs/onesignal-info
  oneSignalUserId: string;
  oneSignalPushToken: string;
  oneSignalSubscribed: boolean;
  oneSignalRequiresUserPrivacyConsent: boolean;
  platform: string;
  appId: string;
  appVersion:  string;
  distribution: string;
  hardware: string;
  installationId: string;
  language: string;
  model: string;
  os: string;
  osVersion: string;
  timeZone: string;
}

@Injectable({
  providedIn: 'root'
})
export class GonativeService {
  static projectData: ProjectDataService | null = null;

  user: User = null;
  info: OneSignalInfo | null = null;
  isUpdated = false;

  @Select(AuthState.user) user$: Observable<User>;

  constructor(
    private httpClient: HttpClient,
    private projectDataService: ProjectDataService
    ) {
    GonativeService.projectData = this.projectDataService;
  }

  installIfNative(): void {
    if (!GonativeService.isGonative()) {
      return;
    }

    this.user$.subscribe((user: User | null | undefined) => {
      if (user) {
        this.updateIfRequired();
      }
    });

    window['gonative_onesignal_info'] = (info: OneSignalInfo) => {
      this.info = info;
      this.isUpdated = false;
      this.updateIfRequired();
    };

    if (window['gonative'] && window['gonative'].statusbar) {
      window['gonative'].statusbar.set({
        'style': 'dark',
        'color': this.projectDataService.get('status_bar_color'),
        'overlay': false,
      });
    }
  }

  updateIfRequired(): void {
    if (this.isUpdated || !this.info) {
      return;
    }

    const info = this.info;
    const pushToken = info.oneSignalPushToken;

    if (!pushToken) {
      return;
    }

    this.isUpdated = true;

    const data = {
      "providerUserId": info.oneSignalUserId,
      "subscribed": info.oneSignalSubscribed,
      "platform": info.platform,
      "appId": info.appId,
      "appVersion": info.appVersion,
      "hardware": info.hardware,
      "installationId": info.installationId,
      "model": info.model,
      "os": info.os,
      "osVersion": info.osVersion,
      "timezone": info.timeZone
    };

    this.httpClient.put(API_LIST.putPushToken('onesignal', pushToken), data).subscribe(() => {
    });
  }

  static isGonative() {
    return (navigator.userAgent.indexOf('gonative') > -1);
  }

  static isFakeIosGonative() {
    if (!window.screen.height) {
      return false;
    }

    let heightDifference = window.screen.height - window.outerHeight;

    return heightDifference > 150 && /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  static isFakeAndroidGonative() {
    return navigator.userAgent.indexOf('Android') > -1 && navigator.userAgent.indexOf('; wv)') > -1;
  }

  static isIosGonative() {
    return (navigator.userAgent.indexOf('gonative') > -1 && navigator.userAgent.indexOf('GoNativeIOS') > -1) || GonativeService.isFakeIosGonative();
  }

  static isAndroidGonative() {
    return (navigator.userAgent.indexOf('gonative') > -1 && navigator.userAgent.indexOf('GoNativeAndroid') > -1) || GonativeService.isFakeAndroidGonative();
  }

  static isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  static isAndroid() {
    return /android/.test(navigator.userAgent.toLowerCase());
  }

  static getAndroidAppLink() {
    return GonativeService.projectData.get('android_app_link');
  }

  static getiOSAppLink() {
    return GonativeService.projectData.get('ios_app_link');
  }

  static getUniversalAppLink() {
    return GonativeService.projectData.get('universal_app_link');
  }
}
