<div [ngClass]="[initService.subWebsite]" *ngIf="initService.websiteStatus === '1'; else websiteDisabledBox">
  <div>
    <div [ngClass]="initService.pageTplData.pageClass">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #websiteDisabledBox>
  <div class="text-center py-5 my-5">{{initService.websiteDisabledMessage}}</div>
</ng-template>
