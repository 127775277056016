<div class="container-wide">
  <h2 class="h1">How it works</h2>
  <div class="tutorial">
    <div class="tutorial__item">
      <div class="tutorial__item-icon">
        <div class="tutorial__item-icon--wrapper">
          <svg-icon name="pound-new"></svg-icon>
        </div>
      </div>
      <div class="tutorial__item-text">
        <span>Select your prize</span>
      </div>
    </div>
    <div class="tutorial__item">
      <div class="tutorial__item-icon">
        <div class="tutorial__item-icon--wrapper">
          <svg-icon name="question-new"></svg-icon>
        </div>
      </div>
      <div class="tutorial__item-text">
        <span>Select amount of entries</span>
      </div>
    </div>
    <div class="tutorial__item">
      <div class="tutorial__item-icon">
        <div
          class="tutorial__item-icon--wrapper tutorial__item-icon--wrapper-transparent"
        >
          <svg-icon name="video"></svg-icon>
        </div>
      </div>
      <div class="tutorial__item-text">
        <span>Winner announced on live draw</span>
        <p>
          You'll be contacted if you win! LIVE Draws every Tuesday, Thursday &
          Sunday 9PM.
        </p>
      </div>
    </div>
    <div class="tutorial__item">
      <div class="tutorial__item-text">
        <span>We are rated excellent on trustpilot</span>
        <app-trustpilot [config]="trustpilotConfig"></app-trustpilot>
      </div>
    </div>
  </div>
</div>
