import { Component, OnInit, Input, ChangeDetectionStrategy, ElementRef, isDevMode, HostBinding } from '@angular/core';
import { SvgIconService } from '@core/services/svg-icon/svg-icon.service';

@Component({
  selector: 'svg-icon',
  template: '',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SvgIconComponent implements OnInit {
  private _name: string;

  @Input() fill = 'currentColor';

  @Input() set name(name: string) {
    this._name = name;
  }
  get name(): string {
    return this._name;
  }
  @Input() set icon(name: string) {
    this._name = name;
  }
  @Input() set spin(val: boolean) {
    this._isSpin = val;
  }

  @HostBinding('class.spinning') _isSpin: boolean;

  constructor(private host: ElementRef,
              private svgIconService: SvgIconService) { }

  get element(): HTMLElement {
    return this.host.nativeElement;
  }

  ngOnInit() {
    this.element.setAttribute('role', 'img');
    this.renderIcon();
  }

  renderIcon() {
    if (this.name && this.svgIconService.hasIcon(this.name)) {
      this.element.innerHTML = this.svgIconService.getIcon(this.name);
      this.element.firstChild['style']['fill'] = this.fill;
    } else {
      this.element.innerHTML = this.svgIconService.getDefaultIcon();

      if (isDevMode()) {
        console.error(`⚠️ Icon '${this.name}' is missing!`);
      }
    }
  }
}
