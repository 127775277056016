import { Injectable } from '@angular/core';
import {ProjectDataModel} from '../../../../../projects/project-data.default';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {
  data: ProjectDataModel;

  setData(data: ProjectDataModel): void {
    this.data = data;
  }

  getData(): ProjectDataModel {
    return this.data;
  }

  get(key: string, defaultValue: any = ''): any {
    return this.data[key] ? this.data[key] : defaultValue;
  }

  exists(key: string): boolean {
    return !!this.data[key];
  }

  set(key: string, value: any): void {
    this.data[key] = value;
  }
}

export const appData = { // used mostly for routing cache and "back" browser action
  navigationTrigger: '',
};
