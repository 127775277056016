import { Component, OnInit } from '@angular/core';
import { InitService } from '@core/services/init/init.service';
import { SafePlayService } from '@core/services/safe-play/safe-play.service';
import { DomainMigratorService } from '@project/core/services/domain-migrator/domain-migrator.service';
import { projectData } from '@project/project-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    public initService: InitService,
    private domainMigrationService: DomainMigratorService,
    private safePlayService: SafePlayService
  ) {
    this.initService.init({ projectData });
    this.domainMigrationService.init();
  }

  ngOnInit(): void {
    this.safePlayService.handleScheduledMessages(
      'button_border',
      'my-account/safe-gambling'
    );
  }
}
