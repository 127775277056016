import { AgainCartItemData, Cart, CartItem } from '@core/models/cart.model';

export class SetCartData {
  static readonly type = '[Cart] SetCartData';
  constructor(public cart: Cart | null) { }
}

export class SetCartId {
  static readonly type = '[Cart] SetCartId';
  constructor(public id: string) { }
}

export class AddAgainCartError {
  static readonly type = '[Cart] AddAgainCartError';
  constructor(public error: {id: number; message: string}) { }
}

export class AddCartError {
  static readonly type = '[Cart] AddCartError';
  constructor(public error: string) { }
}

export class AddAgainCart {
  static readonly type = '[Cart] AddAgainCart';
  constructor(public againCartItem: AgainCartItemData | null) { }
}

export class ClearAgainCart {
  static readonly type = '[Cart] ClearAgainCart';
}

export class ClearAgainCartError {
  static readonly type = '[Cart] ClearAgainCartError';
}

export class ClearCartErrors {
  static readonly type = '[Cart] ClearCartErrors';
}

export class UpdateCartItem {
  static readonly type = '[Cart] UpdateCartItem';
  constructor(public cartItem: CartItem) { }
}
