<div class="raffles-nav__placeholder" #navPositionRef></div>

<div [ngClass]="navFixed" class="raffles-nav__outer" [class.raffles-nav__outer--short]="isNavShort" [style.top]="navAbsoluteTop + 'px'"
  [style.position]="navCssPosition">
  <div class="raffles-nav__inner" (mousedown)="startScrollingNav($event)" (touchstart)="startScrollingNav($event)"
    #navInnerRef>
    <div class="raffles-nav__swiper" #navSwiperRef>


        <div *ngIf="isLoading && (!items || !items.length)">
          <div class="raffles-nav__link">
            <div class="raffles-nav__link-value--full">
              <app-text-holder [desktopTexts]="[80, 140]"></app-text-holder>
            </div>
            <div class="raffles-nav__link-value--short">
              <app-text-holder [desktopTexts]="[80, 140]"></app-text-holder>
            </div>
          </div>
        </div>

        <div *ngFor="let item of items" class="raffles-nav__item">
          <div (click)="handleItemNavClick($event, item)" (touchend)="handleItemNavClick($event, item)"
            class="raffles-nav__link" [class.raffles-nav__link--active]="item === activeItem"
            [id]="'nav-item-' + item.idAttr">
            <div class="raffles-nav__link-value--full">{{item.fullName}}</div>
            <div class="raffles-nav__link-value--short">{{item.name}}</div>
          </div>
        </div>
    </div>
  </div>
</div>