import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DateParserService } from '@core/services/date-parser/date-parser.service';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  standalone: true,
  imports: [CommonModule, NgIf]
})
export class CountdownComponent implements OnDestroy, OnChanges {

  @Input() expiredAt?: any;
  @Input() type?: 'single' | 'list' | 'safe' = 'list';
  @Output() onExpired = new EventEmitter<boolean>();

  public counter: number;
  public timerValue: number;
  public timerStr: string;

  private _alive: Subject<boolean> = new Subject<boolean>();
  private intervalSubscription$: Subscription;

  readonly countdownTempl = '<div><span> --</span> <span> days </span></div> <div><span> -- </span> <span> hrs </span></div> <div><span>--</span> <span> min </span></div> <div><span> --</span> <span> sec </span></div>';

  constructor() {
  }


  ngOnChanges(changes: SimpleChanges) {
    const endAtDate = DateParserService.parse(this.expiredAt);
    const endAtTime = Math.round(endAtDate.getTime() / 1000);
    const timeLeft = endAtTime - Math.round((new Date()).getTime() / 1000);
    this.countdown(timeLeft + 1);
  }

  ngOnDestroy(): void {
    this._alive.next(true);
    this._alive.complete();
  }

  public countdown(val) {
    const timeWhenThePageWasLoaded = Date.now() / 1000;
    this.counter = val;

    if (this.counter > 0) {
      if (this.intervalSubscription$) {
        this.intervalSubscription$.unsubscribe();
      }
      this.intervalSubscription$ = timer(333, 333).pipe(
        takeUntil(this._alive)
      ).subscribe(res => {
        const timeNow = Date.now() / 1000;
        this.counter = Math.floor(val - (timeNow - timeWhenThePageWasLoaded));
        if (this.counter <= 0) {
          this.onExpired.emit(true);
          this.timerStr = this.countdownTempl;
        } else {
          const days = Math.floor((this.counter / 86400));
          const hours = Math.floor((this.counter % 86400) / 3600);
          const minutes = Math.floor((this.counter % 86400) % 3600 / 60);
          const seconds = this.counter % 60;
          const daysStr = `${days >= 10 ? `${days}` : `0${days}`}`;
          const hoursStr = `${hours >= 10 ? `${hours}` : `0${hours}`}`;
          const minutesStr = `${minutes >= 10 ? `${minutes}` : `0${minutes}`}`;
          const secondsStr = `${seconds >= 10 ? `${seconds}` : `0${seconds}`}`;
          if (this.type !== 'safe') {
            this.timerStr = ` ${days > 0 ? `<div><span> ${daysStr}</span> <span> days </span></div>` : ''}
            <div><span> ${hours > 0 ? hoursStr : '00'}</span> <span> hrs </span></div>
            <div><span> ${minutes > 0 ? minutesStr : '00'}</span> <span> min </span></div>
            <div><span> ${seconds > 0 ? secondsStr : '00'}</span> <span> sec </span></div>`;
          } else {
            let daysStrHtml = '';
            let hoursStrHtml = '';
            let minutesStrHtml = '';
            let secondsStrHtml = '';
            let seroStrHtml = '<span>0</span><span>0</span> ';

            daysStr.split('').map(el => daysStrHtml = `${daysStrHtml}<span>${el}</span>` );
            hoursStr.split('').map(el => hoursStrHtml = `${hoursStrHtml}<span>${el}</span>` );
            minutesStr.split('').map(el => minutesStrHtml = `${minutesStrHtml}<span>${el}</span>` );
            secondsStr.split('').map(el => secondsStrHtml = `${secondsStrHtml}<span>${el}</span>` );

            this.timerStr = ` ${days > 0 ? `<div><span> ${daysStrHtml}</span> <span> days </span></div>` : ''}
            <div><span> ${hours > 0 ? hoursStrHtml : seroStrHtml}</span> <span> hrs </span></div>
            <div><span> ${minutes > 0 ? minutesStrHtml : seroStrHtml}</span> <span> min </span></div>
            <div><span> ${seconds > 0 ? secondsStrHtml : seroStrHtml}</span> <span> sec </span></div>`;
          }
        }

      });
    } else {
      this.onExpired.emit(true);
      this.timerStr = this.countdownTempl;
    }
  }
}
