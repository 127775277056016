import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import {
  ScheduledMessage,
  ScheduledMessageType
} from '@core/models/user.model';
import { TzDatePipe } from '@shared/datetime/custom-pipes/tz-date/tz-date.pipe';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-safe-play-control-dialog',
  templateUrl: './safe-play-control-dialog.component.html',
  styleUrls: ['./safe-play-control-dialog.component.scss'],
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    SvgIconComponent
  ],
  providers: [TzDatePipe, CurrencyPipe],
  standalone: true
})
export class SafePlayControlDialogComponent implements OnInit {
  scheduledMessageType = ScheduledMessageType;
  dialogTitle = '';
  dialogContentTitle = '';
  dialogContentSubtitle = '';
  monthlySpendLimitReached = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scheduledMessage: ScheduledMessage;
      learnMoreBtn: string;
      redirectUrl: string;
      monthlySpendLimit: number;
      monthlySpend: number;
    },
    private tzDatePipe: TzDatePipe,
    private router: Router,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.setDialogData();
  }

  learnMore(): void {
    this.router.navigate([this.data.redirectUrl]);
  }

  private setDialogData(): void {
    const endDate = this.tzDatePipe.transform(
      this.data.scheduledMessage.scheduled_max_at,
      'dd/MM/yy'
    );
    const isPermamentTimeout = this.isPermamentTimeout(
      this.data.scheduledMessage.scheduled_max_at
    );

    switch (this.data.scheduledMessage.type) {
      case ScheduledMessageType.Spend:
        this.setContentSpend();
        break;
      case ScheduledMessageType.Timeout:
        this.setContentTimeout(isPermamentTimeout, endDate);
        break;
      case ScheduledMessageType.TimeoutIW:
        this.setContentTimeoutIW(isPermamentTimeout, endDate);
        break;
      default:
        break;
    }
  }

  private isPermamentTimeout(scheduledMaxAt: Date): boolean {
    const currentDate = dayjs();
    // "permament" is 50+ years
    const date50YearsFromNow = currentDate.add(50, 'year');
    const dateToCheck = dayjs(scheduledMaxAt);

    return dayjs(dateToCheck).isAfter(date50YearsFromNow);
  }

  private setContentSpend(): void {
    this.monthlySpendLimitReached =
      this.data.monthlySpend >= this.data.monthlySpendLimit;
    const monthlySpendLimit = this.currencyPipe.transform(
      this.data.monthlySpendLimit,
      'GBP',
      'symbol',
      '1.0'
    );
    const monthlyToSpend = this.currencyPipe.transform(
      this.data.monthlySpendLimit - this.data.monthlySpend,
      'GBP',
      'symbol',
      '1.2-2'
    );

    this.dialogTitle = 'Spend limit update';
    this.dialogContentSubtitle = `Our systems matched your account with one that has a ${monthlySpendLimit} monthly cap. Every calendar month, this cap will reset again`;
    if (this.monthlySpendLimitReached) {
      this.dialogContentTitle = 'Your account cannot spend more this month.';
    } else {
      this.dialogContentTitle = `Until next calendar month, you have ${monthlyToSpend} left to spend.`;
    }
  }

  private setContentTimeout(
    isPermamentTimeout: boolean,
    endDate: string
  ): void {
    this.dialogTitle = 'Timeout update';
    if (isPermamentTimeout) {
      this.dialogContentTitle =
        'Your account cannot enter competitions anymore.';
      this.dialogContentSubtitle =
        'Our systems matched your account with one that has a permanent timeout.';
    } else {
      this.dialogContentTitle =
        'Your account cannot enter competitions right now.';
      this.dialogContentSubtitle = `Our systems matched your account with one that has a timeout until ${endDate}. Afterwards you can resume playing again`;
    }
  }

  private setContentTimeoutIW(
    isPermamentTimeout: boolean,
    endDate: string
  ): void {
    this.dialogTitle = 'Timeout update';
    if (isPermamentTimeout) {
      this.dialogContentTitle =
        'Your account cannot enter Instant Win competitions anymore.';
      this.dialogContentSubtitle =
        'Our systems matched your account with one that has a permanent Instant Win-only timeout.';
    } else {
      this.dialogContentTitle =
        'Your account cannot enter Instant Win competitions right now.';
      this.dialogContentSubtitle = `Our systems matched your account with one that has an Instant Win-only timeout until ${endDate}. Afterwards you can resume playing again.`;
    }
  }
}
