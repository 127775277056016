import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { CookieService } from '@core/services/cookie/cookie.service';
import { CountriesService } from '@core/services/countries/countries.service';
import { GonativeService } from '@core/services/gonative/gonative.service';

import { AVAILABLE_COUNTRIES_MAP } from '@components/install-prompt/constants/prompt-available-countries.constant';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { ProjectDataService } from '@core/services/project-data/project-data.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: 0, transform: 'translateY(-100%)' }),
        animate(500, style({ height: '73.5px', transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate(500, style({ height: 0 })),
      ]),
    ])
  ],
  standalone: true,
  imports: [AsyncPipe, NgIf, SvgIconComponent, RouterModule, JsonPipe],
  // TODO: Implement on push strategy
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallPromptComponent {
  @Output() statusPrompt = new EventEmitter<boolean>();
  public title = null;
  public project = null;
  public isIos = false;
  public isActiveAnimation = false;
  public installPromptData$: Observable<{ isShow: boolean, downloadLink: string }> =
    this._countriesService.visitorCountry$
      .pipe(
        filter(() => !+this._cookieService.getCookie('installPrompt')),
        pluck('country_iso'),
        map((country) => {
          return {
            deviceType: GonativeService.isAndroid() && 'android' || GonativeService.isIos() && 'ios',
            country
          };
        }),
        filter(filterData => filterData.deviceType && !GonativeService.isGonative() && AVAILABLE_COUNTRIES_MAP.has(filterData.deviceType) && AVAILABLE_COUNTRIES_MAP.get(filterData.deviceType).includes(filterData.country)),
        pluck('deviceType'),
        distinctUntilChanged(),
        map((deviceType) => {
          setTimeout(() => {
            this.isActiveAnimation = true;
            this.statusPrompt.emit(true);
            this._changeDetectorRef.detectChanges();
          }, 300);

          return {
            isShow: true,
            downloadLink: deviceType === 'android' ? GonativeService.getAndroidAppLink() : GonativeService.getiOSAppLink()
          };
        })
      );

  constructor(
    private _goNativeService: GonativeService,
    private _countriesService: CountriesService,
    private _cookieService: CookieService,
    private _changeDetectorRef: ChangeDetectorRef,
    private projectDataService: ProjectDataService
  ) {
    this.title = this.projectDataService.get('prompt_title');
    this.project = this.projectDataService.get('project');
    this.isIos = GonativeService.isIos();
  }

  public hidePrompt(): void {
    this._cookieService.setCookie('installPrompt', 1, 1000 * 60 * 60 * 24 * 14);
    this.isActiveAnimation = false;
    this.statusPrompt.emit(false);
  }
}
