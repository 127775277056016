import { Injectable } from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';
import {filter, take} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeletionCheckerService {
  @Select(AuthState.user) user$: Observable<User>;

  constructor(private router: Router) { }

  checkIfScheduled() {
    this.user$.pipe(
      take(4),
      filter(user => !!user),
      take(1)
    ).subscribe((user: User) => {
      if (user.scheduled_removal_at) {
        setTimeout(() => {
          this.router.navigate(['/deletion-info']);
        }, 50);
      }
    });
  }
}
