import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_LIST } from '@core/services/web-api/api-list';
import { NewsletterResponse } from '@core/models/newsletter.model';
import { UserengageService } from '@core/services/userengage/userengage.service';
import { SharedModule } from '@project/shared/shared.module';

@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss'],
  imports: [SharedModule],
  standalone: true
})
export class EmailSubscriptionComponent {
  @Input() placeholder = 'Enter your email...';
  @Input() submitName = 'Notify me!';
  @Output() successfulEvent = new EventEmitter<string>();
  @Output() failedEvent = new EventEmitter();

  emailAddress: string;
  errors: string[] = [];
  isSubmitting = false;

  constructor(
    private httpClient: HttpClient,
    private userEngage: UserengageService
  ) { }

  submitEmail() {
    if (!this.emailAddress) {
      return;
    }

    this.isSubmitting = true;

    const data = {
      email: this.emailAddress,
    };

    this.httpClient.post<NewsletterResponse>(
      API_LIST.postNewsletterEmail(window['gtmTrackedCampaignName']), data).subscribe((response: NewsletterResponse) => {
        this.errors = [];
        this.isSubmitting = false;
        this.successfulEvent.emit('<p>You successfully subscribed.</p>');

        if (response.user_key_has_user) {
          this.userEngage.resetAuth();
          this.userEngage.clientUpdate({
            email: this.emailAddress,
          }, false);
          this.userEngage.event('newsletter_signup', {
            'email': this.emailAddress,
            'location': 'faq-page',
          }, false);
          setTimeout(() => {
            this.userEngage.pageHit();
          }, 3000);
        } else {
          this.userEngage.clientUpdate({
            email: this.emailAddress,
          }, false);
        }

        this.emailAddress = '';

      }, (errorResponse: any) => {
        const errorData = errorResponse.error;

        this.isSubmitting = false;
        this.failedEvent.emit(errorResponse);

        if (errorData.errors) {
          this.errors = errorData.errors.map((errorObject) => {
            return errorObject.message;
          });
        } else {
          this.errors = [errorData.string];
        }
      }
      );
  }

}
