import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgFor } from '@angular/common';
import {SvgIconComponent} from '@components/svg-icon/svg-icon.component';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgIconComponent, NgFor],
  standalone: true
})
export class PaymentMethodsComponent {

  public payments = [
    'visa',
    'mastercard-new',
    'google-pay',
    'apple-pay-new',
  ];
}
