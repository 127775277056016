export class TrustpilotConfig {
    template: TrustpilotTemplate;
    theme: TrustpilotTheme;
}

export enum TrustpilotTheme {
    Light = 'light',
    Dark = 'dark'
}

export enum TrustpilotTemplate {
    Free = '5419b6a8b0d04a076446a9ad',
    Mini = '53aa8807dec7e10d38f59f32',
    Default = '5419b6ffb0d04a076446a9af',
    Carousel = '54ad5defc6454f065c28af8b',
    MicroStar = '5419b732fbfb950b10de65e5',
    Horizontal = '5406e65db0d04a09e042d5fc'
}

export class TrustpilotClass implements TrustpilotConfig {
    template: TrustpilotTemplate = TrustpilotTemplate.Default;
    theme: TrustpilotTheme = TrustpilotTheme.Light;
}
