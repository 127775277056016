import { Injectable } from '@angular/core';

@Injectable()
export class PageTplDataService {

  public pageTplData: PageTplDataModel = {
    pageClass: null,
    pageTitle: null
  };

  constructor( ) { }

  public setPageTitle(title: string) {
    this.pageTplData.pageTitle = title;
  }
  public setPageClass(pclass: string) {
    this.pageTplData.pageClass = pclass;
  }

  public getPageTitle() {
    return this.pageTplData.pageTitle;
  }
  public getPageClass() {
    return this.pageTplData.pageClass;
  }
}

export class PageTplDataModel {
  pageClass: string;
  pageTitle: string;
}
