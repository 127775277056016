<button type="button" class="dialog-close" (click)="dialogRef.close()"> <svg-icon name="cross"></svg-icon> </button>
<div class="worldwide-dialog__world-map">
  <img src="/assets/images/{{project}}/world-map.png" alt="" />
</div>
<div class="worldwide-dialog__body">
  <p mat-dialog-title class="worldwide-dialog__title">We Ship Worldwide</p>

  <div mat-dialog-content class="worldwide-dialog__text">
   {{brandName}} accepts customers from all over the world.
  </div>

  <div mat-dialog-actions class="worldwide-dialog__actions">
    <button class="link worldwide-dialog__read-more" type="button" (click)="goToWorldwide()">Read more</button>
    <button type="button" class="button button_border button_no-shadow worldwide-dialog__close" (click)="dialogRef.close()" cdkFocusInitial>Close</button>
  </div>


  <div class="worldwide-dialog__trust">
    <div class="worldwide-dialog__trust-icon"><svg-icon name="visa"></svg-icon></div>
    <div class="worldwide-dialog__trust-icon"><svg-icon name="mastercard-new"></svg-icon></div>
    <div class="worldwide-dialog__trust-icon"><svg-icon name="paypal-new"></svg-icon></div>
  </div>
</div>