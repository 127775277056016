import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { HomePageSlide } from '@core/models/homepage-slide.model';
import { ActivatedRoute, Router } from '@angular/router';


import { SettingsModel } from '@core/models/settings.model';
import { Select } from '@ngxs/store';
import { TrustpilotConfig, TrustpilotTemplate, TrustpilotTheme } from '@core/models/trustpilot.model';
import { SettingsState } from '@store/settings/settings.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CountriesService } from '@core/services/countries/countries.service';
import { GeoCountry, GeoData } from '@core/models/country.model';
import { GtmService } from '@core/services/gtm/gtm.service';
import { MediaType } from '@core/models/media-info.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogWorldwideComponent } from '@components/dialog/worldwide-dialog/worldwide-dialog.component';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit, OnDestroy, OnChanges {
  public readonly TITLE_SIZE_LIMIT_FOR_FONT_REDUCING = 60;
  public readonly MediaType = MediaType;

  public activeSlide = 0;
  public timer: ReturnType<typeof setTimeout>;
  public geoVersion: GeoCountry = '';

  @Input() slides: HomePageSlide[] = [];
  @Input() autoplayTimer: number;
  @Input() winnersCount: number | null = null;
  @Input() prizesCount: number | null = null;

  trustpilotConfig: TrustpilotConfig = {
    template: TrustpilotTemplate.Mini,
    theme: TrustpilotTheme.Dark
  };

  trustpilotSmallConfig: TrustpilotConfig = {
    template: TrustpilotTemplate.Default,
    theme: TrustpilotTheme.Light
  };

  winnersCountShow: number | null = null;
  prizesCountShow: number | null = null;

  public isLoading = true;
  public alive$: Subject<void> = new Subject();

  @Select(SettingsState.settings) settings$: Observable<SettingsModel>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public countriesService: CountriesService,
    private gtm: GtmService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.autoplayTimer) {
      this.startAutoplay();
    }

    this.countriesService.visitorCountry$.subscribe((geoData: GeoData) => {
      if (geoData) {
        this.geoVersion = this.countriesService.getGeoVersionByCountryIso(geoData.country_iso, this.route);
      }
    });

    this.winnersCountShow = this.winnersCount;
    this.prizesCountShow = this.prizesCount;

    this.settings$.pipe(takeUntil(this.alive$)).subscribe((settings: SettingsModel) => {
      if (!settings) {
        return;
      }
      this.winnersCountShow = Number(settings.total_winners);
      this.prizesCountShow = Number(settings.total_prizes_value);
      this.isLoading = false;
    });
  }

  ngOnChanges(): void {
    this.slides.forEach((slide, index) => {
      if (slide.ctaUrl.indexOf('/competition/') !== -1) {
        slide.ctaUrl = `${slide.ctaUrl}${slide.ctaUrl.indexOf('?') !== -1 ? '&' : '?'}from=home-hero`;
      }

      if (slide.type === MediaType.Image) {
        const imageToLoad = new Image();
        imageToLoad.onload = (): void => {
          setTimeout(() => {
            slide.isSourceLoaded = true;
          }, 10);
        };
        imageToLoad.src = slide.sourceUrl;
      }

      if (slide.type === MediaType.Video) {
        // preload thumbnail not to blink
        const imageToLoad = new Image();
        imageToLoad.onload = (): void => {
          setTimeout(() => {
            slide.isSourceLoaded = true;
          }, 10);
        };
        imageToLoad.src = slide.poster;

        // preload video
        const video = document.createElement('video');
        video.preload = index === 0 ? 'auto' : 'metadata';

        setTimeout(() => {
          slide.isSourceLoaded = true;
        }, 600);

        video.onloadedmetadata = (): void => {
          slide.duration = video.duration * 1000;
          setTimeout(() => {
            if (index === 0) {
              this.resetAutoplayTimer(slide.duration);
              this.playVideoSlide(index);
            }
          }, 0);
        };

        video.src = slide.sourceUrl;
      }

    });
  }

  videoLoaded(slide: HomePageSlide): void {
    setTimeout(() => {
      slide.isSourceLoadedPhase2 = true;
    }, 1);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    this.alive$.next();
    this.alive$.complete();
  }

  heroSectionEnterNow(target): void {
    if (!target) {
      return;
    }

    this.gtm.heroSectionEnterNow(target);
  }

  handleSlideNext(): void {
    const currentSlide = this.getSlideCurrentData(this.activeSlide);

    if (currentSlide.type === MediaType.Video) {
      const activeSlide = this.activeSlide;
      setTimeout(() => {
        this.restartVideoSlide(activeSlide);
      }, 300);
    }

    this.activeSlide = this.activeSlide > this.slides.length - 2 ? 0 : this.activeSlide + 1;
    const nextSlide: HomePageSlide = this.getSlideCurrentData(this.activeSlide);

    if (nextSlide.type === MediaType.Video) {
      this.playVideoSlide(this.activeSlide);
    }

    this.resetAutoplayTimer(nextSlide.duration);
  }

  handleSlidePrev(): void {
    const currentSlide = this.getSlideCurrentData(this.activeSlide);

    if (currentSlide.type === MediaType.Video) {
      const activeSlide = this.activeSlide;
      setTimeout(() => {
        this.restartVideoSlide(activeSlide);
      }, 300);
    }

    this.activeSlide = this.activeSlide < 1 ? this.slides.length - 1 : this.activeSlide - 1;
    const prevSlide = this.getSlideCurrentData(this.activeSlide);

    if (prevSlide.type === MediaType.Video) {
      this.playVideoSlide(this.activeSlide);
    }

    this.resetAutoplayTimer(prevSlide.duration);
  }

  startAutoplay(videoDuration: number | null = null): void {
    const duration = videoDuration && videoDuration > this.autoplayTimer ? videoDuration : this.autoplayTimer;
    this.timer = setInterval(() => {
      this.handleSlideNext();
    }, duration);
  }

  resetAutoplayTimer(duration: number | null = null): void {
    if (this.autoplayTimer) {
      clearInterval(this.timer);
      this.startAutoplay(duration);
    }
  }

  navigateIfLocal(url: string, event: Event): void {
    if (URL && url.indexOf('://') > -1) {
      const urlObject = new URL(url);

      if (urlObject.hostname === window.location.hostname) {
        url = urlObject.pathname + urlObject.search + urlObject.hash;
      }
    }

    if (url.indexOf('://') === -1) {

      const navigateUrl = url.indexOf('?') !== -1 ? url.split('?')[0] : url;
      const navigateParams = url.indexOf('?') !== -1 ? url.split('?')[1] : null;
      const queryParamsObj = {};

      if (navigateParams) {
        navigateParams.split('&').map(param => {
          queryParamsObj[param.split('=')[0]] = param.split('=')[1];
        });
      }

      this.router.navigate([navigateUrl], { queryParams: queryParamsObj });
      event.preventDefault();
    }

  }

  openWorldwideDialog(): void {
    this.dialog.open(DialogWorldwideComponent, {
      backdropClass: 'app-overlay-backdrop',
      panelClass: 'worldwide-dialog'
    });
  }

  private getSlideCurrentData(id: number): HomePageSlide {
    return this.slides[id];
  }

  private playVideoSlide(index: number): void {
    const videoSlide  = document.getElementById(`home-slider-video-${index}`) as HTMLVideoElement;

    if (videoSlide) {
      videoSlide.muted = true;
      videoSlide.loop = true;
      videoSlide.play();
    }
  }

  private restartVideoSlide(index: number): void {
    const videoSlide = document.getElementById(`home-slider-video-${index}`) as HTMLVideoElement;
    if (videoSlide) {
      videoSlide.pause();
      videoSlide.currentTime = 0;
    }
  }
}
