import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormatterService } from '@core/services/date-formatter/date-formatter.service';
import { DateParserService } from '@core/services/date-parser/date-parser.service';

@Pipe({
  name: 'tzDate',
  standalone: true
})
export class TzDatePipe implements PipeTransform {
  private datePipe: DatePipe;
  constructor(@Inject(LOCALE_ID) _locale: string) {
    this.datePipe = new DatePipe(_locale);
   }

  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone = 'Europe/London'
  ): string | null {
    let date: Date;

    if (typeof value === 'string') {
      date = new Date(DateParserService.parse(value));
    } else {
      date = <Date>value;
    }

    if (!timezone) {
      timezone = 'Europe/London';
    }

    const timezoneOffset = DateFormatterService.getUkTimezone(date);
    return this.datePipe.transform(value, format, timezoneOffset);
  }
}
