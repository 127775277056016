import {Injectable} from '@angular/core';
import {ProjectDataService} from "@core/services/project-data/project-data.service";
import {GonativeService} from "@core/services/gonative/gonative.service";

@Injectable({
  providedIn: 'root'
})
export class DomainMigratorService {
  readonly STORAGE_REDIRECT_KEY = 'UKCC_REDIRECTED';
  readonly DISABLE_PARAMETER = 'dm_disable';

  private domainFrom: string;
  private domainTo: string;

  constructor(
    private projectDataService: ProjectDataService,
  ) {
  }

  init(): void {
    this.domainFrom = this.projectDataService.get('domain_migration_from');
    this.domainTo = this.projectDataService.get('domain_migration_to');

    // Only if correctly configured
    if (!this.domainFrom || !this.domainTo) {
      return;
    }

    // Ignore iOS and apps for now
    if (GonativeService.isGonative() && this.getCurrentDomainMatch(this.domainFrom)) {
      if (GonativeService.isIos()) {
        alert('You use old version of the app. Please update it at AppStore.');

        // Don't redirect from ukcarpcompetitions.co.uk because probably it's the old version of the app.
        // If the app is the new version, it won't go through this code, because it uses backend and /process-via/
        return;
      } else if (GonativeService.isAndroid()) {
        // alert('You use old version of the app. Please update it at Google Play.');
      }
    }

    // Won't work without localStorage
    if (typeof (Storage) === "undefined") {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const disabledDM = urlParams.get(this.DISABLE_PARAMETER);

    // Disable if we detect the DM parameter
    if (disabledDM) {
      return;
    }

    // Only if we are currently at old domain
    if (this.getCurrentDomainMatch(this.domainFrom)) {
        this.doRedirectToOldDomain();
    } else if (this.getCurrentDomainMatch(this.domainTo)) {
       if (!this.getHasBeenRedirected()) {
         // Don't redirect longer from the new domain. Uncomment below if a new migration exists.
         // this.doRedirectToOldDomain();
      }
    }

  }

  doRedirectToOldDomain(): void {
    // Mark as redirected at least once, don't do anything if we don't have storage anyway
    localStorage.setItem(this.STORAGE_REDIRECT_KEY, String(Math.floor(Date.now() / 1000)));

    let path = btoa(window.location.pathname + window.location.search);
    path = path.replace(/\+/g, '-').replace(/\//g, '_');

    window.location.href = `https://${this.domainFrom}/process-via/domain-migrator/from/${path}`;
  }

  getCurrentDomainMatch(domain: string): boolean {
    const currentHostname = window.location.host.toLowerCase().replace('www.', '');
    return currentHostname === domain.toLowerCase();
  }

  getHasBeenRedirected(): boolean {
    // @trepatudo:
    // Safely return true if we can't detect localStorage to avoid infinite loop
    const isRedirected = localStorage.getItem(this.STORAGE_REDIRECT_KEY);
    return isRedirected !== null ? !!isRedirected : false;
  }

}
