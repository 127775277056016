import { Component, Input, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_LIST} from '@core/services/web-api/api-list';
import {BrandStatistics} from '@core/models/brand-statistics.model';
import {Select} from '@ngxs/store';
import {SettingsState} from '@store/settings/settings.state';
import {Observable, Subject} from 'rxjs';
import {SettingsModel} from '@core/models/settings.model';
import {takeUntil} from 'rxjs/operators';
import {SharedModule} from '@project/shared/shared.module';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
  imports: [SharedModule],
  standalone: true
})
export class InfoBarComponent implements OnInit {
  @Input() winnersCount: number | null = null;
  @Input() prizesCount: number | null = null;
  @Input() hasconfettiDecor: boolean;

  winnersCountShow: number | null = null;
  prizesCountShow: number | null = null;

  public alive$: Subject<void> = new Subject();

  @Select(SettingsState.settings) settings$: Observable<SettingsModel>;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.winnersCountShow = this.winnersCount;
    this.prizesCountShow = this.prizesCount;

    this.settings$.pipe(takeUntil(this.alive$)).subscribe((settings: SettingsModel) => {
      if (!settings) {
        return;
      }

      this.winnersCountShow = Number(settings.total_winners);
      this.prizesCountShow = Number(settings.total_prizes_value);
    });
  }
}
