import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { WhyChooseUsComponent } from './components/why-choose-us/why-choose-us.component';
import { RafflesNavComponent } from './components/raffles-nav/raffles-nav.component';
import { CompetitionSectionComponent } from './components/competition-section/competition-section.component';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import { RatedSectionComponent } from './components/rated-section/rated-section.component';
import { ReviewsSharedModule } from '@project/shared/reviews-shared/reviews-shared.module';
import { TrustpilotComponent } from '@components/trustpilot/trustpilot.component';
import { HowItWorksComponent } from '@project/components/how-it-works/how-it-works.component';
import { RaffleCardComponent } from '@project/components/raffle-card/raffle-card.component';
import { TopAvailabilityMessageComponent } from '@project/components/top-availability-message/top-availability-message.component';
import { TextHolderComponent } from '@components/text-holder/text-holder.component';
import { WinnersSectionComponent } from '@project/components/winners-section/winners-section.component';
import { DialogWorldwideComponent } from '@components/dialog/worldwide-dialog/worldwide-dialog.component';
import { TemplateModule } from '@project/shared/template/template.module';


SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

@NgModule({
  declarations: [
    HomeComponent,
    HomeSliderComponent,
    DownloadAppComponent,
    WhyChooseUsComponent,
    RafflesNavComponent,
    CompetitionSectionComponent,
    RatedSectionComponent
  ],
  imports: [
    TemplateModule,
    RaffleCardComponent,
    HomeRoutingModule,
    ReviewsSharedModule,
    TrustpilotComponent,
    SwiperModule,
    TopAvailabilityMessageComponent,
    TextHolderComponent,
    WinnersSectionComponent,
    HowItWorksComponent,
    DialogWorldwideComponent,
  ]
})
export class HomeModule { }
