import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class MersenneTwister {
  /*
  TypeScript version of JavaScript code: https://github.com/boo1ean/mersenne-twister/blob/master/src/mersenne-twister.js
   */

  private N: number = 624;
  private M: number = 397;
  private MATRIX_A: number = 0x9908b0df;
  private UPPER_MASK: number = 0x80000000;
  private LOWER_MASK: number = 0x7fffffff;
  private mt: number[] = [];
  private mti: number = this.N + 1;

  constructor(seed: number|null = null) {
    if (!seed) {
      seed = seed = new Date().getTime();
    }

    this.initSeed(seed);
  }

  initSeed(seed: number): void {
    this.mt[0] = seed >>> 0;

    for (this.mti = 1; this.mti < this.N; this.mti++) {
      let s = this.mt[this.mti - 1] ^ (this.mt[this.mti - 1] >>> 30);
      this.mt[this.mti] = (((((s & 0xffff0000) >>> 16) * 1812433253) << 16) + (s & 0x0000ffff) * 1812433253) + this.mti;
      /* See Knuth TAOCP Vol2. 3rd Ed. P.106 for multiplier. */
      /* In the previous versions, MSBs of the seed affect   */
      /* only MSBs of the array mt[].                        */
      /* 2002/01/09 modified by Makoto Matsumoto             */
      this.mt[this.mti] >>>= 0;
      /* for >32 bit machines */
    }
  }

  initByArray(initKey, keyLength): void {
    let i: number = 1,
      j: number = 0,
      k: number = (this.N > keyLength ? this.N : keyLength);
    this.initSeed(19650218);

    for (; k; k--) {
      let s = this.mt[i-1] ^ (this.mt[i-1] >>> 30)
      this.mt[i] = (this.mt[i] ^ (((((s & 0xffff0000) >>> 16) * 1664525) << 16) + ((s & 0x0000ffff) * 1664525)))
        + initKey[j] + j; /* non linear */
      this.mt[i] >>>= 0; /* for WORDSIZE > 32 machines */
      i++; j++;

      if (i>=this.N) {
        this.mt[0] = this.mt[this.N-1];
        i = 1;
      }

      if (j >= keyLength) {
        j = 0;
      }
    }

    for (k = this.N-1; k; k--) {
      let s = this.mt[i-1] ^ (this.mt[i-1] >>> 30);
      this.mt[i] = (this.mt[i] ^ (((((s & 0xffff0000) >>> 16) * 1566083941) << 16) + (s & 0x0000ffff) * 1566083941))
        - i; /* non linear */
      this.mt[i] >>>= 0; /* for WORDSIZE > 32 machines */
      i++;

      if (i>=this.N) {
        this.mt[0] = this.mt[this.N-1];
        i=1;
      }
    }

    this.mt[0] = 0x80000000; /* MSB is 1; assuring non-zero initial array */
  }

  randomNumber(min: number = 0, max: number = 2147483647) {
    let randomInRange = Math.floor(this.random() * (max - min + 1) + min);

    return Math.min(randomInRange, max);
  }

  randomInt() {
    let y;
    let mag01: number[] = [0x0, this.MATRIX_A];
    /* mag01[x] = x * MATRIX_A  for x=0,1 */

    if (this.mti >= this.N) { /* generate N words at one time */
      let kk;

      if (this.mti == this.N + 1) { /* if init_seed() has not been called, */
        this.initSeed(5489);  /* a default initial seed is used */
      }

      for (kk = 0; kk < this.N - this.M; kk++) {
        y = (this.mt[kk] & this.UPPER_MASK) | (this.mt[kk + 1] & this.LOWER_MASK);
        this.mt[kk] = this.mt[kk + this.M] ^ (y >>> 1) ^ mag01[y & 0x1];
      }

      for (; kk < this.N - 1; kk++) {
        y = (this.mt[kk] & this.UPPER_MASK) | (this.mt[kk + 1] & this.LOWER_MASK);
        this.mt[kk] = this.mt[kk + (this.M - this.N)] ^ (y >>> 1) ^ mag01[y & 0x1];
      }

      y = (this.mt[this.N - 1] & this.UPPER_MASK) | (this.mt[0] & this.LOWER_MASK);
      this.mt[this.N - 1] = this.mt[this.M - 1] ^ (y >>> 1) ^ mag01[y & 0x1];

      this.mti = 0;
    }

    y = this.mt[this.mti++];

    /* Tempering */
    y ^= (y >>> 11);
    y ^= (y << 7) & 0x9d2c5680;
    y ^= (y << 15) & 0xefc60000;
    y ^= (y >>> 18);

    return y >>> 0;
  }

  randomInt31() {
    return (this.randomInt() >>> 1);
  }

  randomIncl() {
    return this.randomInt() * (1.0 / 4294967295.0);
    /* divided by 2^32-1 */
  }

  random() {
    return this.randomInt() * (1.0 / 4294967296.0);
    /* divided by 2^32 */
  }

  randomExcl() {
    return (this.randomInt() + 0.5) * (1.0 / 4294967296.0);
    /* divided by 2^32 */
  }

  randomLong() {
    let a = this.randomInt() >>> 5, b = this.randomInt() >>> 6;
    return (a * 67108864.0 + b) * (1.0 / 9007199254740992.0);
  }
}
