import { Injectable } from '@angular/core';

export type FbContentItem = {id: string, quantity: number};
export type FbContents = Array<FbContentItem>;

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor() { }

  addedToCart(eventId: string, itemName: string, itemId: string, amount: number, currency: string, quantity: number) {
    this.pushRawEvent('frontend-added-to-cart', {
      'tracking_event_id': eventId,
      'item_id': itemId,
      'item_name': itemName,
      'amount': amount,
      'currency': currency,
      'quantity': quantity
    });
  }

  itemView(itemName: string, itemId: string, amount: number, currency: string) {
    this.pushRawEvent('frontend-item-view', {
      'item_id': itemId,
      'item_name': itemName,
      'amount': amount,
      'currency': currency
    });
  }

  cartPaymentStarted(fbContents: FbContents, amount: number, currency: string, quantity: number) {
    this.pushRawEvent('frontend-cart-payment-started', {
      'fb_contents': fbContents,
      'amount': amount,
      'currency': currency,
      'quantity': quantity
    });
  }

  registrationFull(eventId: string) {
    this.pushRawEvent('frontend-registration-full', {
      'tracking_event_id': eventId
    });
  }

  crossSellEnterNow(targetId, sourceId) {
    this.pushRawEvent('cross-sell-cart-window', {
      'target_id': String(targetId),
      'source_id': String(sourceId),
      'ga_p1': String(targetId),
      'ga_p2': String(sourceId),
    });
  }

  heroSectionEnterNow(target) {
    this.pushRawEvent('hero-section-enter', {
      'target': String(target),
      'ga_p1': String(target),
    });
  }

  login() {
    this.pushRawEvent('frontend-login');
  }

  registrationLead(eventId: string) {
    this.pushRawEvent('frontend-registration-lead', {
      'tracking_event_id': eventId
    });
  }

  pushRawEvent(event: string, data: any = {}) {
    if (!window['dataLayer'] || !window['dataLayer'].push) {
      return;
    }

    if (typeof data !== 'object' || data === null) {
      data = {};
    }

    data.event = event;
    window['dataLayer'].push(data);
  }
}
