export class MediaInfo {
  url: string;
  big: string;
  reduced: string;
  original: string;
  thumbnail: string;
  is360view: boolean;
  type: MediaType;
  attributes: {[key: string]: any};
  options: {[key: string]: any};
}

export enum MediaType {
  Video = 'video',
  Image = 'image',
  Panorama = 'panorama',
}
