import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {API_LIST} from "@core/services/web-api/api-list";
import {share, map, mergeMap} from 'rxjs/operators';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class UserDrawTicketCountersService {
  private counters: {[key: string]: number} = {};

  @Select(AuthState.isAuthenticated) isLoggedIn$: Observable<boolean>;

  constructor(private httpClient: HttpClient) {}

  clearCounters() {
    this.counters = {};
  }

  getCounter(slug: string): Observable<number> {
    return this.isLoggedIn$.pipe(mergeMap((isLoggedIn) => {
      if (!isLoggedIn) {
        return of(0);
      }

      if (this.counters[slug]) {
        return of(this.counters[slug]);
      }

      const observable = this.httpClient.get<{value: number}>(API_LIST.getUserGameTicketsCount(slug))
      .pipe(map((item: {value: number}) => {
        return item.value;
      }))
      .pipe(share());

      observable.subscribe((count: number) => {
        this.counters[slug] = count;
      });

      return observable;
    }));
  }
}
