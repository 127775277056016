import { NgModule } from '@angular/core';
import { DateUnifierPipe } from './custom-pipes/date-unifier/date-unifier.pipe';
import { TzDatePipe } from './custom-pipes/tz-date/tz-date.pipe';
import { UkTimezonePipe } from './custom-pipes/uk-timezone/uk-timezone.pipe';
import {CommonModule} from '@angular/common';
import {NgPipesModule} from 'ngx-pipes';

@NgModule({
  exports: [
    CommonModule,
    NgPipesModule,
    DateUnifierPipe,
    TzDatePipe,
    UkTimezonePipe,
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    DateUnifierPipe,
    TzDatePipe,
    UkTimezonePipe,
  ],
  declarations: [
  ]
})
export class DatetimeModule {
  constructor() {
  }
}
