
<div class="container-wide">
<h2 class="h1">Our latest winners</h2>
  <swiper  #swiperRef class="winners" [config]="config">
    <ng-template swiperSlide *ngFor="let winner of winners">
      <a class="winners__item" [routerLink]="['']">
        <div class="winners__item--media">
          <img [src]="winner.url" [alt]="winner.title">
        </div>
        <div class="winners__item--body">
          <span class="winners__item--title">{{winner.name}}</span> 
          <span class="winners__item--subtitle">{{winner.title}}</span> 
        </div>
      </a>
    </ng-template>
  </swiper>

</div>
<div class="container-wide">
  <div class="row text-center justify-content-center">
    <a [routerLink]="ctaLink" class="button-new button-new__link">View all winners</a>
  </div>
</div>