import { AsyncPipe, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ProjectDataService } from '@core/services/project-data/project-data.service';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-app-banner.component.html',
  styleUrls: ['./header-app-banner.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgFor, SvgIconComponent],
})
export class HeaderAppBannerComponent{
  @Input() text: string = null;
  itemCounter: Array<any> = [];
  public project = null;

  constructor(
    private projectDataService: ProjectDataService
  ) {
    this.itemCounter = Array.from({ length: 40 }, (v, k) => k + 1);
    this.project = this.projectDataService.get('project');
  }

}
