<div class="download">
  <div class="download__head">
    <h2 class="h1">Download Our App</h2>
    <p>The easy way to play. Get app exclusive competitions and discounts!</p>
  </div>
  <div class="download__body">
    <img src="assets/images/ukcarp/download-app.png" alt="">
  </div>
  <div class="download__footer">
    <a *ngIf="!isIos" href="https://apps.apple.com/gb/app/uk-carp-competitions/id1637378310" class="download__footer__item download__footer__item--app" target="_blank">
      <img src="assets/images/download/apple.svg" alt="">
    </a>
    <a *ngIf="isIos" href="https://apps.apple.com/gb/app/uk-carp-competitions/id1637378310?mt=8" class="download__footer__item download__footer__item--app">
      <img src="assets/images/download/apple.svg" alt="">
    </a>
    <a href="https://play.google.com/store/apps/details?id=uk.co.ukcarpcompetitions.android&hl=en_US" target="_blank" class="download__footer__item download__footer__item--gp">
      <img src="assets/images/download/android-app.png" alt="">
    </a>
  </div>
</div>