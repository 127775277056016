import { Event, NavigationError } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionControlService {
  public reloadPageOnceChunkLoadingFailed(navigationEvent: Event): void {
    if (navigationEvent instanceof NavigationError ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const errorMessage = navigationEvent.error.message as string;
      const chunkFailedPatternRegex = /Loading chunk [\S]+ failed/;

      if (errorMessage && chunkFailedPatternRegex.test(errorMessage)) {
        window.location.href = navigationEvent.url;
      }
    }
  }
}
