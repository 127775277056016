<div class="safe-play-control-dialog-container">
  <button
    type="button"
    class="dialog-close"
    [mat-dialog-close]="data.scheduledMessage.id"
  >
    <svg-icon
      name="cross"
      class="safe-play-control-dialog-close-icon"
    ></svg-icon>
  </button>
  <div mat-dialog-title class="safe-play-control-dialog-title">{{
    dialogTitle
  }}</div>
  <mat-dialog-content class="safe-play-control-dialog-content">
    <div
      class="safe-play-control-dialog-reason"
      [ngClass]="{
        'safe-play-control-dialog-reason-warning': monthlySpendLimitReached,
        'safe-play-control-dialog-reason-notice': !monthlySpendLimitReached
      }"
    >
      <svg-icon
        name="info-icon"
        class="safe-play-control-dialog-icon"
        fill="none"
      ></svg-icon>
      <span>{{ dialogContentTitle }}</span>
    </div>
    <div>
      {{ dialogContentSubtitle }}
    </div>
    <div>
      For any questions contact
      <span class="safe-play-control-dialog-email"
        >hello&#64;7daysperformance.co.uk</span
      >
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="safe-play-control-dialog-actions">
    <button
      class="button button_no-shadow safe-play-control-dialog-button"
      [ngClass]="data.learnMoreBtn"
      [mat-dialog-close]="data.scheduledMessage.id"
      (click)="learnMore()"
      >Learn more</button
    >
    <button
      [mat-dialog-close]="data.scheduledMessage.id"
      cdkFocusInitial
      class="button button_border button_blue button_no-shadow safe-play-control-dialog-button"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</div>
