import { LowerCasePipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DateFormatterService } from '@core/services/date-formatter/date-formatter.service';
import {TemplateModule} from '@project/shared/template/template.module';

@Component({
  selector: 'app-raffle-card-badge',
  templateUrl: './raffle-card-badge.component.html',
  styleUrls: ['./raffle-card-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    LowerCasePipe,
    TemplateModule
  ]
})
export class RaffleCardBadgeComponent {
  @Input() isOpen: boolean;
  @Input() endAt: Date | string;
  @Input() resultAt: Date | string;
  @Input() short: boolean;

  @HostBinding('style.display') get active(): string {
    return this.isMixed ? 'none' : null;
  }

  get drawTimeVersion(): 'today' | 'tomorrow' | 'thisweek' | 'launched' | '' {
    return DateFormatterService.drawRelativeTimeVersion(this.resultAt);
  }

  get isMixed(): boolean {
    const duration = DateFormatterService.getDateDiff(this.endAt, this.resultAt, 'mins');
    return duration > 60 && this.isOpen
  }
}
