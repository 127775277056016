import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';
import { Observable } from 'rxjs';
import { SettingsModel } from '@core/models/settings.model';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-top-availability-message',
  templateUrl: './top-availability-message.component.html',
  styleUrls: ['./top-availability-message.component.scss'],
  imports: [NgIf, AsyncPipe],
  standalone: true
})
export class TopAvailabilityMessageComponent implements OnInit {
  // @TODO: check about unbound method in ngxs
  @Select(SettingsState.settings) settings$: Observable<SettingsModel>;
  paymentDisabledMessage$: Observable<string>;

  ngOnInit(): void {
    this.paymentDisabledMessage$ = this.settings$.pipe(
      map((settings: SettingsModel) => !settings || settings.payments_status !== '0' ? null : settings.payments_disabled_message_homepage)
    );
  }
}
