import { Pipe, PipeTransform } from '@angular/core';
import {DateParserService} from '@core/services/date-parser/date-parser.service';
import {DateFormatterService} from '@core/services/date-formatter/date-formatter.service';

@Pipe({
  name: 'ukTimezone',
  standalone: true
})
export class UkTimezonePipe implements PipeTransform {
  transform(
    value: string | number | Date,
    showAlways = false
  ): string {
    let date: Date;

    if (typeof value == 'string') {
      date = new Date(DateParserService.parse(value));
    } else {
      date = <Date> value;
    }

    const localTimezoneOffset = DateFormatterService.getLocalTimezoneOffset(date);
    const ukTimezoneOffset = DateFormatterService.getUkTimezone(date);

    if (!showAlways && localTimezoneOffset == ukTimezoneOffset) {
      return '';
    }

    return ukTimezoneOffset == '+0100' ? 'BST' : 'GMT';
  }
}
