import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, share, tap, first, map, mergeMap } from 'rxjs/operators';
import { Cart } from '@core/models/cart.model';
import { Select, Store } from '@ngxs/store';
import { API_LIST } from '@core/services/web-api/api-list';
import { CartState, CartStateModel } from '@store/cart/cart.state';
import { AddCartError, SetCartData, UpdateCartItem } from '@store/cart/cart.actions';
import { MessagePopupService } from '@core/services/message-popup/message-popup.service';
import {AbTestsService} from '@core/services/ab-tests/ab-tests.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  postCartRequest$: Observable<Cart> | null;
  getCartRequest$: Observable<Cart> | null;
  errorSubject = new Subject();
  cartId: string | null;

  @Select(CartState.state) state$: Observable<CartStateModel>;

  constructor(
    private httpClient: HttpClient,
    private messagePopup: MessagePopupService,
    private store: Store,
    private abTests: AbTestsService
  ) {
    this.state$.subscribe((state) => {
      if (!state || !state.isInitiated) {
        return;
      }

      if (!state.id) {
        this.createCart();
      } else {
        this.cartId = state.id;
      }

      if (this.cartId && !state.cart) {
        this.refreshCart();
      }

      if (state.cart) {
        this.checkForCartMessage(state.cart);
      }
    });
  }

  addDrawItem(drawId: number, quantity: number, userInput1: string | null, userInput2: string | null, trigger: string | null = null, iterationsCount: number | null = null): Observable<any> {
    if (!this.cartId) {
      return of(null);
    }

    let data: object = {
      type: 'CompetitionType',
      typeId1: drawId,
      quantity: quantity,
      trigger: trigger,
    };

    if (userInput1) {
      data = {
        ...data,
        userInput1,
        userInput2
      };
    }

    return this.httpClient.post(API_LIST.postCartItem(this.cartId), data).pipe(
      tap((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));

        this.abTests.sendEvent('add-to-cart', true, String(drawId));
      }),
      catchError((err) => {
        if (err.error.code === 404 && iterationsCount !== null && iterationsCount < 2) {

          this.postCartRequest$ = null;
          return this.createCart().pipe(mergeMap(() => throwError('resend')));

        } else {
          if (err.error.errors) {
            for (let error of err.error.errors) {
              this.store.dispatch(new AddCartError(error.message));
            }
          } else if (err.error.string) {
            this.store.dispatch(new AddCartError(err.error.string));
          } else {
            this.store.dispatch(new AddCartError(err.message));
          }

          return throwError(err);
        }

      })
    );
  }

  createCart(): Observable<Cart> {
    if (!this.postCartRequest$) {
      this.postCartRequest$ = <Observable<Cart>>this.httpClient.post(API_LIST.postCart(), {}).pipe(share());

      this.postCartRequest$.subscribe((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));

        setTimeout(() => {
          this.postCartRequest$ = null;
        }, 10);
      });
    }

    return this.postCartRequest$;
  }

  refreshCart(forceDataRefresh: boolean = false): Observable<Cart> {

    if (!this.cartId) {
      return this.createCart();
    }

    if (!this.getCartRequest$) {
      this.getCartRequest$ = <Observable<Cart>>this.httpClient.get(API_LIST.getCart(this.cartId, forceDataRefresh)).pipe(
        share(),
        catchError((err) => {
          this.postCartRequest$ = null;

          return this.createCart();
        })
      );

      this.getCartRequest$.pipe(first()).subscribe((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));

        setTimeout(() => {
          this.getCartRequest$ = null;
        }, 10);
      });
    }

    return this.getCartRequest$;
  }

  deleteAddons(): Observable<Cart> {
    if (!this.cartId) {
      return of(null);
    }

    this.httpClient.delete(API_LIST.deleteCartAddons(this.cartId)).pipe(
      tap((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));
      })
    );
  }

  applyCoupon(couponCode: string): Observable<Cart> {
    if (!this.cartId) {
      return of(null);
    }

    let data = {
      code: couponCode,
    };

    return this.httpClient.post(API_LIST.postCartCoupon(this.cartId), data).pipe(
      tap((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));
      })
    );
  }

  deleteCoupon(couponCode: string): Observable<Cart> {
    if (!this.cartId) {
      return of(null);
    }

    return this.httpClient.delete(API_LIST.deleteCartCoupon(this.cartId, couponCode)).pipe(
      tap((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));
      })
    );
  }

  deleteItem(itemId: any) {
    if (!this.cartId) {
      return of(null);
    }

    return this.httpClient.delete(API_LIST.deleteCartItem(this.cartId, itemId)).pipe(
      tap((cartData: Cart) => {
        this.store.dispatch(new SetCartData(cartData));
      })
    );
  }

  patchItem(itemId: any, quantity: number) {
    if (!this.cartId) {
      return of(null);
    }

    return this.httpClient.patch(API_LIST.patchCartItem(this.cartId, itemId), { quantity }).pipe(
      tap((cartData: Cart) => {
        this.checkForCartMessage(cartData);
        this.store.dispatch(new SetCartData(cartData));
      })
    );
  }

  checkForCartMessage(cart: Cart) {
    if (cart.messages && cart.messages.length) {
      this.messagePopup.addTextMessage(cart.messages[0], 'error');
    }
  }
}
