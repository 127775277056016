import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { RelativeTime } from '@core/models/date.model';
import { DateParserService } from '@core/services/date-parser/date-parser.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  constructor() {}

  static parseAndFormat(time: number | string | Date, dateFormat: string) {
    return DateFormatterService.format(
      DateParserService.parse(time),
      dateFormat
    );
  }

  static format(time: number | Date, dateFormat: string) {
    let date = new Date(time);

    return formatDate(
      date,
      dateFormat,
      'en-US',
      DateFormatterService.getUkTimezone(date)
    );
  }

  static localTimeOffsetToUk(time: Date) {
    let date = new Date(time);

    return (
      date.getTimezoneOffset() * -1 -
      (DateFormatterService.isDaylightSavingTimeInUk(date) ? 60 : 0)
    );
  }

  static getUkTimezone(time: Date) {
    let date = new Date(time);

    return DateFormatterService.isDaylightSavingTimeInUk(date)
      ? '+0100'
      : '+0000';
  }

  static getTimezoneOffset(date: Date, timezone: string): string | null {
    if (!Intl || !Intl.DateTimeFormat) {
      return null;
    }

    try {
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'longOffset'
      });

      const formattedDatetime = formatter.format(date);
      let [useless, offset] = formattedDatetime.split(' GMT');
      offset = offset.replace(':', '');

      if (offset === '') {
        return '+0000';
      }

      return offset;
    } catch (e) {
      return null;
    }
  }

  static getLocalTimezoneOffset(date: Date): string | null {
    if (!Intl || !Intl.DateTimeFormat) {
      return null;
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'longOffset'
    });

    const formattedDatetime = formatter.format(date);
    let [useless, offset] = formattedDatetime.split(' GMT');
    offset = offset.replace(':', '');

    if (offset === '') {
      return '+0000';
    }

    return offset;
  }

  static isDaylightSavingTimeInUk(time: Date): boolean {
    let date = new Date(time);

    let ukOffset = DateFormatterService.getTimezoneOffset(
      date,
      'Europe/London'
    );

    if (ukOffset == '+0100') {
      return true;
    } else if (ukOffset == '+0000') {
      return false;
    }

    // kind of polyfill
    let jan = new Date(date.getFullYear(), 0, 1);
    let jul = new Date(date.getFullYear(), 6, 1);
    let stdTimezoneOffset = Math.max(
      jan.getTimezoneOffset(),
      jul.getTimezoneOffset()
    );

    return date.getTimezoneOffset() < stdTimezoneOffset;
  }

  static isTodayDate(date: string): boolean {
    const today = new Date();
    const current = new Date(DateParserService.parse(date));

    return (
      DateFormatterService.format(today.getTime(), 'yyyy-MM-dd') ==
      DateFormatterService.format(current.getTime(), 'yyyy-MM-dd')
    );
  }

  static isTomorrowDate(date: string) {
    const tomorrow = new Date();
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

    const current = new Date(DateParserService.parse(date));

    return (
      DateFormatterService.format(tomorrow.getTime(), 'yyyy-MM-dd') ==
      DateFormatterService.format(current.getTime(), 'yyyy-MM-dd')
    );
  }

  static getDateDiff(
    date1: Date | string,
    date2: Date | string,
    period = 'days'
  ): number {
    if (!date1 || !date2) {
      return 0;
    }

    date1 = new Date(date1);
    date2 = new Date(date2);

    let diff;

    switch (period) {
      case 'days':
        const MS_IN_DAY = 1000 * 60 * 60 * 24;
        diff = (date2.getTime() - date1.getTime()) / MS_IN_DAY;
        break;
      case 'hours':
        const MS_IN_HOUR = 1000 * 60 * 60;
        diff = (date2.getTime() - date1.getTime()) / MS_IN_HOUR;
        break;
      case 'mins':
        const MS_IN_MINS = 1000 * 60;
        diff = (date2.getTime() - date1.getTime()) / MS_IN_MINS;
        break;

      default:
        break;
    }
    return diff;
  }

  static drawRelativeTimeVersion(
    endDate
  ): 'today' | 'tomorrow' | 'thisweek' | 'launched' | '' {
    if (DateFormatterService.isTodayDate(endDate)) {
      return 'today';
    }

    if (DateFormatterService.isTomorrowDate(endDate)) {
      return 'tomorrow';
    }

    let daysLeft = DateFormatterService.daysLeft(endDate);

    if (daysLeft < 6 && daysLeft >= 1) {
      return 'thisweek';
    }

    if (daysLeft > 1) {
      return 'launched';
    }

    return '';
  }

  static daysLeft(endDate): number {
    return DateFormatterService.getDateDiff(new Date(), endDate);
  }

  static convertToRelativeTime(endAt: Date): RelativeTime {
    const targetDate = dayjs(endAt);
    const today = dayjs();
    const tomorrow = today.add(1, 'day').endOf('day');
    const endOfThisWeek = today.add(6, 'day').endOf('day');
    const startOfNextWeek = today.add(7, 'day').startOf('day');
    const endOfNextWeek = today.add(13, 'day').endOf('day');

    if (today.isAfter(targetDate)) {
      return RelativeTime.Past;
    } else if (targetDate.isSame(today, 'day')) {
      return RelativeTime.Today;
    } else if (targetDate.isSame(tomorrow, 'day')) {
      return RelativeTime.Tomorrow;
    } else if (
      targetDate.isAfter(tomorrow) &&
      targetDate.isBefore(endOfThisWeek)
    ) {
      return RelativeTime.ThisWeek;
    } else if (
      targetDate.isAfter(startOfNextWeek) &&
      targetDate.isBefore(endOfNextWeek)
    ) {
      return RelativeTime.NextWeek;
    } else if (targetDate.isAfter(endOfNextWeek)) {
      return RelativeTime.Future;
    }
  }
}
