<div #trustboxRef class="trustpilot-widget"
     [attr.id]="trustpilotId"
     data-locale="en-GB"
     [attr.data-businessunit-id]="trustpilotId"
     data-style-width="100%"
     [attr.data-style-height]="height"
     [attr.data-theme]="config.theme"
     [attr.data-template-id]="config.template"
>
  <a  [attr.href]="trustpilotUrl"  target="_blank" rel="noopener">
  </a>
</div>
