<section class="info-bar" [ngClass]="{'info-bar_confetti': hasconfettiDecor}">
  <div class="info-bar__col">
    <div class="info-bar__wrapper">
      <img class="info-bar__image" src="/assets/images/ukcarp/new-tag.png" alt="">
      <h2 class="info-bar__number" *ngIf="winnersCountShow !== null">{{ winnersCountShow }}</h2>
      <h2 class="info-bar__number" *ngIf="winnersCountShow === null"><app-text-holder [desktopTexts]="[60]"></app-text-holder></h2>
      <p class="info-bar__title">Winners</p>
    </div>
  </div>
  <div class="info-bar__col">
    <div class="info-bar__wrapper">
      <img class="info-bar__image" src="/assets/images/ukcarp/pound-sign.png" alt="">
      <h2 class="info-bar__number" *ngIf="prizesCountShow !== null">{{ prizesCountShow | currency:'GBP':'symbol':'1.0' }}</h2>
      <h2 class="info-bar__number" *ngIf="prizesCountShow === null"><app-text-holder [desktopTexts]="[200]" [mobileRatio]="0.5"></app-text-holder></h2>
      <p class="info-bar__title">Given in Prizes</p>
    </div>
  </div>
</section>

<section class="section section_trustpilot info-bar__trustpilot-box" [ngClass]="{'info-bar__trustpilot-box_confetti-style': hasconfettiDecor}">
  <div class="container">
    <img class="section__image section__image_info-bar" src="/assets/images/ukcarp/image_from_ios.jpg" alt="" srcset="
        assets/images/ukcarp/image_from_ios.jpg 2201w,
        assets/images/ukcarp/image_from_ios-1024x289.jpg 1024w,
        assets/images/ukcarp/image_from_ios-768x217.jpg 768w,
        assets/images/ukcarp/image_from_ios-1536x433.jpg 1536w,
        assets/images/ukcarp/image_from_ios-2048x578.jpg 2048w,
        assets/images/ukcarp/image_from_ios-600x169.jpg 600w" sizes="(max-width: 2201px) 100vw, 2201px
    ">
  </div>
</section>
