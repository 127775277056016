<div class="footer">
  <div class="footer__top">
    <div class="footer__top-group">
      <a routerLink="/" class="footer__logo" title="UKCC">
        <img src="/assets/svg/logo.svg" alt="UKCC" class="footer__logo-image">
      </a>
      <div class="footer__nav">
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/competitions">All Competitions</a></li>
          <li><a routerLink="/fishing">Fishing</a></li>
          <li><a routerLink="/vehicle">Vehicles</a></li>
          <li><a routerLink="/tech-and-cash">Tech & Cash</a></li>
          <li><a routerLink="/instant-wins">Instant Wins</a></li>
          <li><a routerLink="/draw-results">Draw Results</a></li>
          <li><a routerLink="/legal/competition-terms-conditions">Terms & Conditions</a></li>
          <li><a routerLink="/legal/privacy-and-cookie-policy">Privacy and Cookie Policy</a></li>
        </ul>
        <ul>
          <li><a routerLink="/winners">Winners</a></li>
          <li *ngIf="!isNativeAppVisible && (isIos || isAndroid)">
            <a *ngIf="isAndroid" target="_blank" href="https://play.google.com/store/apps/details?id=uk.co.ukcarpcompetitions.android&hl=en_US">Download Our App</a>
            <a *ngIf="isIos" target="_blank" href="https://apps.apple.com/gb/app/uk-carp-competitions/id1637378310">Download Our App</a>
          </li>
          <li><a routerLink="/faq">FAQs</a></li>
          <li><a routerLink="/security">Security</a></li>
          <li><a routerLink="/legal/responsible-playing">Responsible Playing</a></li>
          <li><a routerLink="/contact">Contact</a></li>
        </ul>
      </div>
    </div>
    <ul class="footer__social-list">
      <li>
        <a href="https://www.facebook.com/ukcc.co.uk/" target="_blank" rel="nofollow" class="footer__social-link" title="">
          <svg-icon name="facebook-new"></svg-icon>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/ukcc.co.uk/" target="_blank" rel="nofollow" class="footer__social-link" title="">
          <svg-icon name="instagram-new"></svg-icon>
        </a>
      </li>
      <!-- <li>
        <a href="#" target="_blank" rel="nofollow" class="footer__social-link" title="">
          <svg-icon name="youtube-new"></svg-icon>
        </a>
      </li> -->
    </ul>
  </div>
  <div class="footer__aside">
    <div class="footer__aside-info">
      <a href="mailto:info@ukcc.co.uk">info&#64;ukcc.co.uk</a>
      <p>
        <span>©UK Carp Competitions Ltd - Registered Company No: 13211311</span>
        <span>You must be 18 or over to enter</span>
      </p>
      <p class="footer__powered-info">
        UKCC - powered by
        <a href="https://7dayslabs.co.uk/" target="_blank"><u>7days Labs</u></a>
      </p>
      <ul class="footer__social-list">
        <li>
          <a href="https://www.facebook.com/ukcc.co.uk/" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="facebook-new"></svg-icon>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/ukcc.co.uk/" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="instagram-new"></svg-icon>
          </a>
        </li>
        <!-- <li>
          <a href="#" target="_blank" rel="nofollow" class="footer__social-link" title="">
            <svg-icon name="youtube-new"></svg-icon>
          </a>
        </li> -->
      </ul>

    </div>
    <div class="footer__aside-payment">
      <div class="footer__aside-reviews">
        <app-trustpilot [height]="'40px'" [config]="trustpilotConfig"></app-trustpilot>
      </div>
      <app-payment-methods class="footer__aside-payment-list"></app-payment-methods>
    </div>
  </div>
</div>