import { NgModule } from '@angular/core';
import { SharedModule } from '@project/shared/shared.module';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { EmailSubscriptionComponent } from './components/email-subscription/email-subscription.component';
import { InfoBarBrandedComponent } from './components/info-bar-branded/info-bar-branded.component';
import { FixOnChangesDirective } from './directives/fix-on-changes/fix-on-changes.directive';
import { OnlyNumbersDirective } from './directives/only-numbers/only-numbers.directive';
import { DatetimeModule } from '@shared/datetime/datetime.module';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { FooterComponent } from '@project/shared/template/components/footer/footer.component';
import { HeaderComponent } from '@project/shared/template/components/header/header.component';
import { PaymentMethodsComponent } from '@project/shared/template/components/payment-methods/payment-methods.component';
import { TrustpilotComponent } from '@components/trustpilot/trustpilot.component';

@NgModule({
  exports: [
    SharedModule,
    InfoBarComponent,
    EmailSubscriptionComponent,
    FixOnChangesDirective,
    OnlyNumbersDirective,
    InfoBarBrandedComponent,
    SvgIconComponent,
    FooterComponent,
    HeaderComponent,
    PaymentMethodsComponent,
    DatetimeModule,
    TrustpilotComponent
  ],
  imports: [
    SharedModule,
    EmailSubscriptionComponent,
    InfoBarComponent,
    SvgIconComponent,
    FooterComponent,
    HeaderComponent,
    PaymentMethodsComponent,
    DatetimeModule,
    TrustpilotComponent
  ],
  declarations: [
    FixOnChangesDirective,
    OnlyNumbersDirective,
    InfoBarBrandedComponent,
  ]
})
export class TemplateModule {
  constructor() {
  }
}
