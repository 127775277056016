import { NgFor, NgForOf } from '@angular/common';
import { Component, Input, OnChanges, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'app-text-holder',
  templateUrl: './text-holder.component.html',
  styleUrls: ['./text-holder.component.scss'],
  imports: [NgFor, NgForOf],
  standalone: true
})
export class TextHolderComponent implements OnChanges {
  @Input() mobileRatio = 0.7;
  @Input() desktopTexts = [];
  @Input() mobileTexts = [];
  @Input() margin = 7;
  @Input() mobileMargin: number | null;
  @Input() color = '#000000';
  @Input() scaleY = '0.4';
  @Input() opacity = '0.07';

  @HostBinding('style.opacity') hostOpacity: number | string;

  texts = [];
  textMargin = 7;

  ngOnChanges(): void {
    // text widths
    if (window.innerWidth < 678) {
      if (this.mobileTexts.length) {
        this.texts = this.mobileTexts;
      } else {
        const texts = [];

        for (const textWidth of this.desktopTexts) {
          texts.push(Math.round(textWidth * this.mobileRatio));
        }

        this.texts = texts;
      }
    } else {
      this.texts = this.desktopTexts;
    }

    // margin
    if (window.innerWidth < 678) {
      if (this.mobileMargin) {
        this.textMargin = this.mobileMargin;
      } else {
        this.textMargin = Math.round(this.margin * this.mobileRatio);
      }
    } else {
      this.textMargin = this.margin;
    }

    this.hostOpacity = this.opacity;
  }

  @HostListener('window:resize', ['$event']) // for window scroll events
  onWindowChange(): void {
    this.ngOnChanges();
  }
}
