  <app-header (headerSizeShortChange)="changeHeaderState($event)"></app-header>

<app-top-availability-message></app-top-availability-message>

<app-home-slider [slides]="slides" [autoplayTimer]="8000"></app-home-slider>


<div id="competitions-wrapper" class="competitions-wrapper bg-default">
  <div class="raffles-nav-container">
    <app-raffles-nav [headerIsShort]="headerIsShort" [items]="rafflesNavItems" [isLoading]="isLoadingNavigation"></app-raffles-nav>
  </div>
  <ng-container *ngFor="let section of competitionSections; let index = index; let first = first">
    <section class="section section_cards bg-default" *ngIf="section.isAvailable" [id]="section.idAttr">
      <div class="container-wide">
        <div class="competitions-wrapper--header " [class.competitions-wrapper--header__first]="first">
          <h2 class="h1">{{section.headingName}}</h2>

          <ng-container *ngIf="index === 0">
            <app-trustpilot [height]="'25px'" [config]="trustpilotConfig"></app-trustpilot>
          </ng-container>
        </div>

        <div class="competitions-wrapper--body">
          <app-raffle-card *ngFor="let raffle of section.raffles; trackBy: trackBy" [raffle]="raffle" [version]="section.isOdd ? 'white' : null"></app-raffle-card>
        </div>
      </div>
      <div class="container-wide" *ngIf="section.raffles.length > 4 && section.href">
        <div class="row competitions-wrapper--view-all" >
          <a [routerLink]="[section.href]" class="button-new button-new__link">View all competitions</a>
        </div>
      </div>
    </section>
  </ng-container>
</div>

<app-how-it-works class="bg-default"></app-how-it-works>
<!-- <app-why-choose-us></app-why-choose-us>
<app-how-to-play></app-how-to-play> -->
<!-- <app-reviews-google></app-reviews-google> -->
<!-- <app-rated-section class="container-wide bg-default"></app-rated-section> -->
<app-winners-section class="bg-default" *ngIf="winners && winners.length" [winners]="winners" [ctaLink]="['/winners']"></app-winners-section>

<app-download-app *ngIf="!isNativeAppVisible"></app-download-app>

<app-footer></app-footer>
