import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GonativeService } from '@core/services/gonative/gonative.service';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppComponent { 
  public isIos = false;
  constructor(
  ) {
    this.isIos = GonativeService.isIos();
  }
  
}
