import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Select} from '@ngxs/store';
import {AuthState, AuthStateModel} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';
import {RaffleSimple} from '@core/models/raffle.model';
import {DateFormatterService} from '@core/services/date-formatter/date-formatter.service';
import {ProjectDataService} from '@core/services/project-data/project-data.service';

export type UeRequest = {[key: string]: any};
export type UeProductRequest = {
  product_id: string;
  name: string;
  image_url: string;
  price: string;
  stocklevel: string;
  stocklevel_sold: string;
  stocklevel_total: string;
  product_url: string;
  categories: {[key: string]: boolean};
  draw_at: string;
  [key: string]: any;
};

@Injectable({
  providedIn: 'root'
})
export class UserengageService {
  private isUserLoaded: boolean = false;
  private user: User | null = null;
  private debugMode = 0; // from 0 to 3

  @Select(AuthState.isLoadingUser) isLoadingUser$: Observable<boolean>;
  @Select(AuthState.user) user$: Observable<User>;

  constructor(private router: Router, private route: ActivatedRoute, private projectDataService: ProjectDataService) {
    window['UeRequest'] = this;

    const urlParams = new URLSearchParams(window.location.search);
    const ueDebugParam = urlParams.get('uedebug');

    if (ueDebugParam) {
      this.setDebugMode(Number(ueDebugParam));
    }

    if (this.debugMode >= 2) {
      console.log('UeRequest.init');
    }
  }

  installPageListener() {
    this.isLoadingUser$.subscribe((isLoadingUser) => {
      this.isUserLoaded = this.isUserLoaded || !isLoadingUser; // one-way switch false->true for isUserLoaded

      if (this.debugMode >= 3) {
        console.log('UE.isLoadingUser$: isUserLoaded: ', this.isUserLoaded);
      }
    });

    this.user$.subscribe((user) => {
      this.user = user;

      if (this.debugMode >= 3) {
        console.log('UE.user$: isUserLoaded: ', this.isUserLoaded);
      }

      if (this.isUserLoaded) {
        this.pageHit();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.isUserLoaded) {
        this.pageHit();
      }
    });
  }

  pageHit(data: UeRequest = {}, assignUserId: boolean = true) {
    if (!this.isInstalled('pageHit')) {
      return;
    }

    let dataToMerge: UeRequest = { apiKey: this.getApiKey() };

    if (this.user && assignUserId) {
      dataToMerge.user_id = this.user.id;
    }

    data = {...dataToMerge, ...data};

    if (this.debugMode >= 2) {
      console.log('UE.pageHit: triggered with data: ', data);
    }

    window['UE'].pageHit(data);
  }

  clientUpdate(data: UeRequest = {}, assignUserId: boolean = true) {
    if (!this.isInstalled('clientUpdate')) {
      return;
    }

    let dataToMerge: UeRequest = { apiKey: this.getApiKey() };

    if (this.user && assignUserId) {
      dataToMerge.user_id = this.user.id;
    }

    data = {...dataToMerge, ...data};

    if (this.debugMode >= 2) {
      console.log('UE.clientUpdate: triggered with data: ', data);
    }

    window['userengage']('client.update', data);
  }

  resetAuth(data: UeRequest = {}) {
    if (!this.isInstalled('resetAuth')) {
      return;
    }

    let dataToMerge: UeRequest = { apiKey: this.getApiKey() };
    data = {...dataToMerge, ...data};

    if (this.debugMode >= 2) {
      console.log('UE.resetAuth: triggered with data: ', data);
    }

    window['UE'].resetAuth(data);
  }

  registration(user: User, pageKey: string) {
    let data: {[key: string]: any} = {
      user_id: user.id,
      email: user.email,
    };

    if (user.first_name) {
      data.name = user.first_name + ' ' + user.last_name;
    }

    this.pageHit(data);

    setTimeout(() => { // ensure that pageHit triggered first
      this.event('register_success', {
        user_id: user.id,
        name: user.first_name ? (user.first_name + ' ' + user.last_name) : '',
        email: user.email,
        location: pageKey,
      });
    }, 40);
  }

  productEventByProduct(type: string, product: RaffleSimple) {
    let categories = {};

    if (product.category_ids) {
      for (let categoryId of product.category_ids) {
        categories[categoryId] = true;
      }
    }

    this.productEvent(type, {
      product_id: product.id,
      name: product.title,
      image_url: product.thumbnail.big,
      price: product.offers[0].price,
      stocklevel: product.max_entries > 0 ? String(product.max_entries - product.current_entries) : '100',
      stocklevel_total: product.max_entries > 0 ? String(product.max_entries) : '100',
      stocklevel_sold: product.current_entries > 0 ? String(product.current_entries) : '0',
      product_url: window.origin + '/' + this.projectDataService.get('product_page_path') + '/' + product.slug,
      categories: categories,
      'category_99p-competitions': !!categories['99p-competitions'],
      'category_cash-competitions': !!categories['cash-competitions'],
      'category_current-competitions': !!categories['current-competitions'],
      'category_current-tech-competitions': !!categories['current-tech-competitions'],
      'category_site-credit': !!categories['site-credit'],
      draw_at: DateFormatterService.parseAndFormat(product.result_at, 'yyyy-MM-ddTHH:mm:ssZ'),
    });
  }

  productEvent(type: string, data: UeRequest) {
    if (!this.isInstalled('productEvent')) {
      return;
    }

    let dataToMerge: UeRequest = { event_type: type };

    if (this.user) {
      dataToMerge.user_id = this.user.id;
    }

    data = {...dataToMerge, ...data};

    if (this.debugMode >= 2) {
      console.log('UE.productEvent: triggered with data: ', data);
    }

    window['userengage']('product_event', data);
  }

  event(name: string, data: UeRequest, assignUserId: boolean = true) {
    if (!this.isInstalled('event')) {
      return;
    }

    let dataToMerge: UeRequest = {};

    if (this.user && assignUserId) {
      dataToMerge.user_id = this.user.id;
    }

    data = {...dataToMerge, ...data};

    if (this.debugMode >= 2) {
      console.log('UE.event.' + name + ': triggered with data: ', data);
    }

    window['userengage']('event.' + name, data);
  }

  getApiKey() {
    return window['ENVIRONMENT_PARAMETERS']['userengage_apikey'];
  }

  isInstalled(checkerId = 'core') {
    if (!window['UE']) {
      if (this.debugMode >= 1) {
        console.log('UE.' + checkerId + ': UE not found');
      }

      return false;
    }

    if (!window['userengage']) {
      if (this.debugMode >= 1) {
        console.log('UE.' + checkerId + ': userengage not found');
      }

      return false;
    }

    if (!window['ENVIRONMENT_PARAMETERS']['userengage_apikey']) {
      if (this.debugMode >= 1) {
        console.log('UE.' + checkerId + ': userengage_apikey not found');
      }

      return false;
    }

    return true;
  }

  setDebugMode(mode: number) {
    this.debugMode = mode;
    console.log('UE.setDebugMode: ' + mode);
  }
}
