export const GetUserEngageKey = (defaultValue: string | null = null): string | null => {
  const cookieName = '__ca__chat';

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return defaultValue;
}
