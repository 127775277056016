import { ProjectDataModel } from '../../../project-data.default';

export class ProjectData extends ProjectDataModel {
  project: string = 'ukcarp';
  main_domain: string = 'ukcc.co.uk';
  brand_name_full: string = 'UKCC';
  prompt_title: string = 'UKCC';
  brand_name: string = 'UKCC';
  brand_name_short: string = 'UKCC';
  email_address: string = 'info@ukcc.co.uk';
  phone_number: string | null = null;
  brand_color_main: string = '#55acee';
  status_bar_color: string = '03989E';

  meta_title: string = 'UKCC - Win Vehicles, Carp Gear, Tech & Cash';
  meta_description: string = 'UKCC';
  og_site_name: string = 'UKCC';
  og_description: string = 'UKCC';
  twitter_theme_color: string = '#55acee';

  product_page_path = 'competition';
  cart_page_path = 'basket';

  trustpilot_id = '666996768bfe9c50c2eede1b';
  trustpilot_url = 'https://www.trustpilot.com/review/ukcc.co.uk';
  facebook_url = 'https://www.facebook.com/ukcc.co.uk';
  facebook_live_videos_url = 'https://www.facebook.com/ukcc.co.uk/live_videos';
  youtube_url = '';
  youtube_streams_url = '';
  instagram_url = 'https://www.instagram.com/ukcc.co.uk/';
  android_app_link = 'https://play.google.com/store/apps/details?id=uk.co.ukcarpcompetitions.android&hl=en_US';
  ios_app_link = 'https://apps.apple.com/gb/app/uk-carp-competitions/id1637378310';
  universal_app_link = 'https://ukcc.co.uk/process-via/native-app?openbrowser=true';

  domain_migration_from = window['ENVIRONMENT_PARAMETERS']['domain_migration_from'] || '';
  domain_migration_to = window['ENVIRONMENT_PARAMETERS']['domain_migration_to'] || '';
}

export const projectData: ProjectDataModel = new ProjectData();
