import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

// to load GTM before
window.addEventListener("load", function(event) {
  // document.querySelector('#bootstrap-loader')['style']['display'] = 'none';
  // document.querySelector('body > app-root')['style']['display'] = 'block';
});

platformBrowserDynamic().bootstrapModule(AppModule)
.then(success => {
  // document.querySelector('#bootstrap-loader')['style']['display'] = 'none';
  // document.querySelector('body > app-root')['style']['display'] = 'block';
})
.catch(err => console.log(err));
