import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { TrustpilotClass, TrustpilotConfig } from '@core/models/trustpilot.model';
import { ProjectDataService } from '@core/services/project-data/project-data.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TrustpilotComponent implements AfterViewInit, OnDestroy {
  @ViewChild('trustboxRef') trustboxRef;
  @Input() config: TrustpilotConfig = new TrustpilotClass();
  @Input() height = 'fit-content';

  isTrustpilotLoaded = false;
  trustpilotUrl = null;
  trustpilotId = null;
  public _alive$: Subject<void> = new Subject();

  constructor(
    private projectDataService: ProjectDataService
  ) {
    this.trustpilotId = this.projectDataService.get('trustpilot_id');
    this.trustpilotUrl = this.projectDataService.get('trustpilot_url');
  }

  ngAfterViewInit(): void {
    this.initReviews();
  }

  initReviews() {
    if (window['Trustpilot'] && this.trustboxRef && this.trustboxRef.nativeElement) {
      window['Trustpilot'].loadFromElement(this.trustboxRef.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this._alive$.next();
    this._alive$.complete();
  }
}
