import { AsyncPipe, NgIf, NgFor, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SwiperOptions } from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';

@Component({
  selector: 'app-winners-section[winners]',
  templateUrl: './winners-section.component.html',
  styleUrls: ['./winners-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, AsyncPipe, NgIf, NgFor, NgClass, RouterModule, SwiperModule],
})
export class WinnersSectionComponent {
  @Input() winners: { url: string, title: string, name: string }[];
  @Input() ctaLink: string[];

  config: SwiperOptions = {
    centeredSlides: false,
    loop: false,
    slidesPerView: 2,
    slidesPerGroup: 2,
    autoHeight: false,
    spaceBetween: 10,
    allowTouchMove: true,
    pagination: {
      clickable: true
    },
    navigation: true,
    breakpoints: {
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1500: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    }
  };

}
