import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActivateIfLoggedIn, ActivateIfNotLoggedIn } from '@core/services/auth-guard/auth-guard.service';
import { PageTplDataService } from '@core/services/page-tpl-data/page-tpl-data.service';
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, Thumbs]);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    ActivateIfLoggedIn,
    ActivateIfNotLoggedIn,
    PageTplDataService,
    {
      provide: 'AB_CONF',
      useValue: [
        { 'guess.quick': 100 } // , 'guess.range': 50
      ]
    },
  ]
})
export class CoreModule { }
