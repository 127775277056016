import { Injectable } from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AppsflyerService {
  @Select(AuthState.user) user$: Observable<User>;

  constructor() { }

  public initEventRecorderForGtm() {
    window['appsflyer_record_web_event'] = this.recordWebEvent;
    window['appsflyer_record_app_event'] = this.recordAppEvent;

    this.user$.subscribe((user) => {
      if (user && user.id) {
        if (window['AF']) {
          window['AF']('pba', 'setCustomerUserId', '' + user.id);
        }

        if (window['gonative'] && window['gonative']['appsflyer'] && window['gonative']['appsflyer']['setCustomerUserId']) {
          window['gonative']['appsflyer']['setCustomerUserId']('' + user.id);
        }
      }
    });
  }

  public recordWebEvent(eventName, eventValue, extraParameters = {}) {
    if (!eventValue) {
      eventValue = {};
    }

    const parameters = {...extraParameters, eventType: 'EVENT', eventValue: eventValue, eventName: eventName};

    if (window['AF']) {
      if (window['globalUserId']) {
        window['AF']('pba', 'setCustomerUserId', '' + window['globalUserId']);
      }

      window['AF']('pba', 'event', parameters);
    }
  }

  public recordAppEvent(eventName, eventValues) {
    if (!eventValues) {
      eventValues = {};
    }

    if (window['gonative'] && window['gonative']['appsflyer']) {
      if (window['globalUserId'] && window['gonative']['appsflyer']['setCustomerUserId']) {
        window['gonative']['appsflyer']['setCustomerUserId']('' + window['globalUserId']);
      }

      if (window['gonative']['appsflyer']['logEvent']) {
        window['gonative']['appsflyer']['logEvent'](eventName, eventValues);
      }
    }
  }
}
