import { Injectable } from '@angular/core';

export const storageTrackingName = '_tdata';

@Injectable({
  providedIn: 'root'
})
export class TrackingDataService {
  private cachedData: any;

  constructor() { }

  getField(fieldName: string) {
    if (!this.cachedData) {
      const rawData = window.localStorage.getItem(storageTrackingName);
      this.cachedData = rawData ? JSON.parse(rawData) : {};
    }

    return this.cachedData[fieldName] ? this.cachedData[fieldName] : null;
  }

  setField(fieldName: string, value: string) {
    const rawData = window.localStorage.getItem(storageTrackingName);
    const data = JSON.parse(rawData);

    if (value === null || value === undefined) {
      delete data[fieldName];
    } else {
      data[fieldName] = value;
    }

    data.change_time = Math.floor((new Date()).getTime() / 1000);

    const serializedData = JSON.stringify(data);
    window.localStorage.setItem(storageTrackingName, serializedData);
    this.cachedData = null;
  }

  clearField(fieldName: string) {
    this.setField(fieldName, null);
  }
}
