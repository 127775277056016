import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { ComponentRef } from '@angular/core';
import {appData} from '@core/services/project-data/project-data.service';

interface DetachedRouteHandleExt extends DetachedRouteHandle {
  componentRef: ComponentRef<{}>;
}

interface RouteCacheRecord {
  tree: DetachedRouteHandleExt | DetachedRouteHandle;
  scrollY: any;
  /**
   * For unclear reasons, when the navigation starts, "retrieve" is called
   * without calling "shouldAttach" first (from "createRouterState").
   * This flag is used to ignore those calls. :-
   * */
  shouldAttachCalled: boolean;
}

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, RouteCacheRecord>();
  scrollY = 0;

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean {
    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = this.getPath(route);
    const data: any = this.storedRouteHandles.get(path);

    if (!data) {
      return null;
    }

    const detachedTree = data.tree as DetachedRouteHandle;
    this.scrollY = data.scrollY;

    if (data.shouldAttachCalled) {
      this.callHook(detachedTree, 'ngOnAttach');
    }

    data.shouldAttachCalled = false;

    return detachedTree;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (appData.navigationTrigger != 'popstate') {
      return false;
    }

    const path = this.getPath(route);
    const data: any = this.storedRouteHandles.get(path);

    if (!data) {
      return false;
    }

    setTimeout(() => {
      window.scrollTo(0, this.scrollY);
    }, 1);

    this.scrollY = 0;

    data.shouldAttachCalled = true;

    return true;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.log('ASK shouldDetach', route.routeConfig, route.routeConfig.component);

    if (route.routeConfig && route.routeConfig.component && !route.routeConfig.children) {
      // console.log('shouldDetach', route.routeConfig, route.routeConfig.component);

      return true;
    }

    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    if (!detachedTree) {
      return;
    }

    const path = this.getPath(route);
    this.storedRouteHandles.set(path, {
      tree: detachedTree,
      scrollY: window.scrollY,
      shouldAttachCalled: false,
    });

    // console.log('store', path, detachedTree['componentRef']['componentType'], detachedTree);

    if (detachedTree) {
      this.callHook(detachedTree, 'ngOnDetach');
    }
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    const uniqueData = {
      path: this.buildPath(route),
      params: route.params,
      queryParams: route.queryParams,
    };

    return JSON.stringify(uniqueData);
  }

  private buildPath(route: ActivatedRouteSnapshot) {
    let path = '';

    while (route) {
      if (route.routeConfig) {
        path = '/' + route.routeConfig.path + path;
      }

      route = route.parent;
    }

    return path;
  }

  private buildPathOld(snapshot: ActivatedRouteSnapshot): string {
    return snapshot.pathFromRoot.join('/');
  }

  private callHook(detachedTree: any, hookName: string): void {
    const componentRef = detachedTree.componentRef;

    if (
      componentRef &&
      componentRef.instance &&
      typeof componentRef.instance[hookName] === 'function'
    ) {
      componentRef.instance[hookName]();
    }
  }
}
