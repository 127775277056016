import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import {catchError} from 'rxjs/operators';
import {ClearAuthData} from '@store/auth/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public store: Store) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = this.store.selectSnapshot(AuthState.token);

      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }

      return next.handle(req).pipe(
        catchError((err) => {
          if (err.status === 403 && token) {
            this.store.dispatch(new ClearAuthData());
            err.error = {
              code: 404,
              string: 'Session expired, we are sorry. Please refresh the page.',
            };
          }

          return throwError(err);
        })
      );
    }
}
