import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagePopupService {
  public messages: MessagePopup[] = [];
  public messages$ = new BehaviorSubject<MessagePopup[]>([]);

  constructor() { }

  removeMessageIndex(index) {
    this.messages.splice(index, 1);
    this.messages$.next(this.messages);
  }

  removeMessage(message: MessagePopup) {
    const index = this.messages.indexOf(message);

    if (index > -1) {
      this.removeMessageIndex(index);
    }
    this.messages$.next(this.messages);
  }

  addMessage(message: MessagePopup) {
    this.messages = [
      ...this.messages,
      message
    ];

    window.scrollTo(0, 0);
    this.messages$.next(this.messages);
  }

  addTextMessage(text: string, type = 'warn') {
    const messagePopup = new MessagePopup();
    messagePopup.text = text;
    messagePopup.type = type;

    this.addMessage(messagePopup);

    setTimeout(() => {
      this.removeMessage(messagePopup);
    }, 10000);
  }

  clearMessages() {
    this.messages = [];
    this.messages$.next(this.messages);
  }
}

export class MessagePopup {
  text = '';
  type = 'warn';
}
