import { AsyncPipe, NgIf, NgFor, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { TrustpilotComponent } from '@components/trustpilot/trustpilot.component';
import { TrustpilotConfig, TrustpilotTemplate, TrustpilotTheme } from '@core/models/trustpilot.model';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, AsyncPipe, NgIf, NgFor, NgClass, RouterModule, TrustpilotComponent],
})
export class HowItWorksComponent {
  @HostBinding('class.bg-default') container = true;
  trustpilotConfig: TrustpilotConfig = {
    template: TrustpilotTemplate.Free,
    theme: TrustpilotTheme.Light
  };
}
