import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloaderService implements PreloadingStrategy {
  constructor() { }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const canLoad = route.data && route.data['preloadPriority'] > 85;

    if (!canLoad) {
      return of(null);
    }

    return load();
  }
}
