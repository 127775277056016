import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { ProjectDataService } from '@core/services/project-data/project-data.service';

@Component({
  selector: 'app-worldwide-dialog',
  templateUrl: './worldwide-dialog.component.html',
  styleUrls: ['./worldwide-dialog.component.scss'],
  imports: [SvgIconComponent, RouterModule],
  standalone: true
})

export class DialogWorldwideComponent {
  brandName = null;
  project = null;

  constructor(
    public dialogRef: MatDialogRef<DialogWorldwideComponent>,
    private projectDataService: ProjectDataService,
    private router: Router,
  ) {
    this.brandName = this.projectDataService.get('brand_name');
    this.project = this.projectDataService.get('project');
   }

   goToWorldwide() {
    this.dialogRef.close();
    setTimeout(() => {
      this.router.navigate(['/worldwide-shipping']);
    }, 100);

   }

}
