import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentMonthlySpend, ScheduledMessage } from '@core/models/user.model';
import { API_LIST } from '@core/services/web-api/api-list';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getScheduledMessages(): Observable<ScheduledMessage[]> {
    return this.httpClient.get<ScheduledMessage[]>(
      API_LIST.getScheduledMessages()
    );
  }

  patchScheduledMessages(slug: string): Observable<void> {
    return this.httpClient.patch<void>(API_LIST.patchScheduledMessages(slug), {
      readConfirmed: true
    });
  }

  getCurrentMonthlySpend(): Observable<CurrentMonthlySpend> {
    return this.httpClient.get<CurrentMonthlySpend>(
      API_LIST.getCurrentMonthlySpend()
    );
  }
}
