import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeletionCheckerService} from '@core/services/deletion-checker/deletion-checker.service';

@Injectable()
export class ActivateIfLoggedIn  {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private deletionChecker: DeletionCheckerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParams['user_session'] || route.queryParams['session_token']) {
      return true;
    }

    return this.isAuthenticated$.pipe(map((isAuthenticated) => {
      if (isAuthenticated) {
        if (!route.data['noDeletionCheck']) {
          this.deletionChecker.checkIfScheduled();
        }

        return true;
      }

      const urlParts = state.url.split('?');
      const queryParams = {
        redirect_url: urlParts[0],
      };

      this.router.navigate(['/my-account'], {queryParams: queryParams, queryParamsHandling: 'merge'});

      return false;
    }));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

@Injectable()
export class ActivateIfNotLoggedIn  {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

  constructor(private router: Router) { }

  canActivate() {
    return this.isAuthenticated$.pipe(map((isAuthenticated) => {
      if (!isAuthenticated) {
        return true;
      } else {
        this.router.navigate(['/my-account'], {queryParamsHandling: 'merge'});
        return false;
      }
    }));
  }

  canActivateChild() {
    return this.canActivate();
  }
}
