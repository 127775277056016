import {User} from '@core/models/user.model';
import {SessionModelResponse} from '@core/models/session-model-response.model';

export class RefreshUser {
  static readonly type = '[Auth] RefreshUser';
}

export class SetSession {
  static readonly type = '[Auth] SetSession';
  constructor(public session: SessionModelResponse | null) { }
}

export class SetUser {
  static readonly type = '[Auth] SetUser';
  constructor(public user: User | null) { }
}

export class ClearAuthData {
  static readonly type = '[Auth] ClearAuthData';
}

export class SetNewToken {
  static readonly type = '[Auth] SetNewToken';
  constructor(public token: string | null) { }
}

export class UpdateUserAddress {
  static readonly type = '[Auth] UpdateAddressFlags';
}
