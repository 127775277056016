
export class ProjectDataModel {
  project: string = 'default';
  main_domain: string = 'domain.co.uk';
  brand_name_full: string = 'Website';
  prompt_title: string = 'Website';
  brand_name: string = 'Website';
  brand_name_short: string = 'Website';
  brand_color_main: string = '#666666';
  status_bar_color: string = 'ffffff';
  email_address: string = 'hello@mail.com';
  phone_number: string = '+1111111111';

  meta_title: string = 'Website';
  meta_description: string = 'Website';
  og_site_name: string = 'Website';
  og_description: string = 'Website';
  twitter_theme_color: string = '#666666';

  product_page_path = 'product';
  cart_page_path = 'cart';

  trustpilot_id = '';
  trustpilot_url = '';
  facebook_url = '';
  facebook_live_videos_url = '';
  youtube_url = '';
  youtube_streams_url = '';
  instagram_url = '';
  android_app_link = '';
  ios_app_link = '';
  universal_app_link = '';

  domain_migration_from = '';
  domain_migration_to = '';

}
