<ng-container [ngSwitch]="drawTimeVersion" *ngIf="!isMixed">
  <div *ngSwitchCase="'today'" class="raffle-card__drawtime raffle-card__drawtime--urgent"
    [class.raffle-card__drawtime--short]="short">
    <span>Draw Today {{resultAt | unifyDate | tzDate: 'ha'}} {{resultAt | ukTimezone}}</span>
  </div>
  <div *ngSwitchCase="'tomorrow'" class="raffle-card__drawtime raffle-card__drawtime--urgent"
    [class.raffle-card__drawtime--short]="short">
    <span>Draw Tomorrow {{resultAt | unifyDate | tzDate: 'ha'}} {{resultAt | ukTimezone}}</span>
  </div>
  <div *ngSwitchCase="'thisweek'" class="raffle-card__drawtime" [class.raffle-card__drawtime--short]="short">
    <span>Draw {{resultAt | unifyDate | tzDate: 'EEEE'}} {{resultAt | unifyDate | tzDate: 'ha' | lowercase}} {{resultAt |
      ukTimezone}}</span>
  </div>
  <div *ngSwitchCase="'launched'" class="raffle-card__drawtime raffle-card__drawtime--launched" [class.raffle-card__drawtime--short]="short">
    <span>Just launched</span>
  </div>
</ng-container>
