import { Injectable } from '@angular/core';
import MersenneTwister from '@core/services/mersenne-twister/mersenne-twister.service';
import {API_LIST} from '@core/services/web-api/api-list';
import {HttpClient} from '@angular/common/http';
import {TrackingDataService} from '@core/services/tracking-data/tracking-data.service';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AbTestsService {
  static DEFAULT_ATTRIBUTION_MINUTES = 7 * 1440;

  constructor(
    private httpClient: HttpClient,
    private trackingDataService: TrackingDataService
  ) {}

  routeTest(testId: string, versionsLength: number, attributionMinutes: number = AbTestsService.DEFAULT_ATTRIBUTION_MINUTES): number {
    const version = this.getVersion(testId, versionsLength);
    this.createTest(testId, version, attributionMinutes);

    return version;
  }

  createTest(testId: string, version: number, attributionMinutes: number = AbTestsService.DEFAULT_ATTRIBUTION_MINUTES): Subscription {
    return this.httpClient.post(API_LIST.abTestCreate(), {
      testId,
      version,
      attributionMinutes,
      seed: this.getLocalSeed(),
      clientId: this.trackingDataService.getField('client'),
    }).subscribe(() => {
      // this.sendEvent('PAGE_LOAD');
    });
  }

  sendEvent(event: string, isUnique: boolean = false, data1?: string, data2?: string): Subscription {
    return this.httpClient.post(API_LIST.abTestEventSend(), {
      event,
      data1,
      data2,
      isUnique,
      clientId: this.trackingDataService.getField('client'),
    }).subscribe(() => {});
  }

  init(): void {
    this.getInitiatedSeed();
  }

  getInitiatedSeed(): number {
    let seed = this.getLocalSeed();

    if (!seed) {
      seed = Math.floor(Math.random() * 2000000000);
      this.setSeed(seed);
    }

    return seed;
  }

  setSeed(seed: number): void {
    localStorage.setItem('ab_test_seed', String(seed));
  }

  getLocalSeed(): number {
    return +localStorage.getItem('ab_test_seed');
  }

  numberHashTestId(testId: string): number {
    let arr = testId.split('');

    return arr.reduce(
      (hashCode, currentVal) =>
        (hashCode =
          currentVal.charCodeAt(0) +
          (hashCode << 6) +
          (hashCode << 16) -
          hashCode),
      0
    );
  }

  getVersion(testId: string, versionsLength: number): number { // from 1 to versionsLength
    const versionSeed = this.getLocalSeed() + this.numberHashTestId(testId);

    const generator = new MersenneTwister(versionSeed);
    return generator.randomNumber(1, versionsLength);
  }
}
