import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from './auth/auth.state';
import { environment } from '@environments/environment';
import { CartState } from '@store/cart/cart.state';
import { SettingsState } from '@store/settings/settings.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AuthState,
      CartState,
      SettingsState
    ]),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.token',
        'cart.id',
      ]
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  declarations: [],
  providers: []
})
export class StoreModule { }
