import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GonativeService } from '@core/services/gonative/gonative.service';

@Component({
  selector: 'app-raffles-nav',
  templateUrl: './raffles-nav.component.html',
  styleUrls: ['./raffles-nav.component.scss']
})
export class RafflesNavComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() items: ItemTab[];

  @Input() set headerIsShort(event: boolean) {
    setTimeout(() => {
      this.keepNavigationInViewport();
      this.doSectionDetection();
    }, 500);
  }
  @Input() isLoading = false;
  @ViewChild('navInnerRef') navInnerRef: ElementRef<HTMLElement> | undefined;
  @ViewChild('navSwiperRef') navSwiperRef: ElementRef<HTMLElement> | undefined;
  @ViewChild('navPositionRef') navPositionRef: ElementRef<HTMLElement> | undefined;

  public navAbsoluteTop = 0;
  public navCssPosition = 'absolute';
  public isNavShort = false;
  public activeItem: ItemTab | null = null;

  public navFixed = {
    'raffles-nav__fixed': false
  };
  public _alive$ = new Subject<void>();
  private lastCursorNavPosition = -1;
  private lastCursorDownNavPosition = -1;
  private isForcedSectionScrolling = false;


  ngOnInit(): void {
    this.installPageScrollListener();
    this.installNavScrollListener();
  }

  ngAfterViewInit(): void {
    if (GonativeService.isIos()) {
      this.navInnerRef.nativeElement.style.overflow = 'auto';
    }

    setTimeout(() => {
      this.keepNavigationInViewport();
      this.doSectionDetection();
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.firstChange && this.items && this.items.length > 0) {
      this.activeItem = this.items[0];
    }
  }

  ngOnDestroy(): void {
    this._alive$.next();
    this._alive$.complete();
  }

  handleItemNavClick(event: Event, item: ItemTab): void {

    const cursorPosition = this.getCursorRelativePositionFromEvent(event);
    const scrollRelative = cursorPosition - this.lastCursorDownNavPosition;

    if (Math.abs(scrollRelative) > 5) {
      // console.log('click cancelled because it was scrolling', item.idAttr);
      return;
    }

    // console.log('clicked', item.idAttr);
    this.activateSection(item);
  }

  activateSection(item: ItemTab): void {
    this.activeItem = item;
    this.scrollNavItemIntoView(item);
    this.scrollToSection(item);
  }

  scrollNavItemIntoView(item: ItemTab): void {
    const itemElem = document.getElementById(this.getNavElemByItem(item)).parentElement;
    const navMenuWidth = this.navInnerRef.nativeElement.offsetWidth;
    const rect = itemElem.getBoundingClientRect();

    if (rect.left < 0) {
      this.navInnerRef.nativeElement.scrollTo({ left: itemElem['offsetLeft'], behavior: 'smooth' });
    } else if (rect.left + itemElem['offsetWidth'] > navMenuWidth) {
      const scrollPos = itemElem['offsetLeft'] - navMenuWidth + itemElem['offsetWidth'];
      this.navInnerRef.nativeElement.scrollTo({ left: scrollPos, behavior: 'smooth' });
    }
  }

  scrollToSection(item: ItemTab): void {
    const sectionElem = document.getElementById(item.idAttr);
    const mainMenuHeight = document.querySelector<HTMLElement>('.header-container').offsetHeight;
    const navMenuHeight = this.navInnerRef.nativeElement.offsetHeight;
    const additionalTopSpace = mainMenuHeight + navMenuHeight;

    const y = sectionElem.getBoundingClientRect().top + window.pageYOffset - additionalTopSpace + 3;
    window.scrollTo({ top: y, behavior: 'smooth' });

    this.isForcedSectionScrolling = true;

    setTimeout(() => {
      this.isForcedSectionScrolling = false;
    }, 1500);
  }

  installPageScrollListener(): void {
    fromEvent(window, 'scroll').
      pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        this.keepNavigationInViewport();
        this.doSectionDetection();
      });

    fromEvent(window, 'resize').
      pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        this.keepNavigationInViewport();
        this.doSectionDetection();
      });
    this.keepNavigationInViewport();
  }

  doSectionDetection(): void {
    if (this.isForcedSectionScrolling) {
      return;
    }

    if (!document.getElementById('competitions-wrapper')) { // if it's deteached from DOMTree
      return;
    }

    const mainMenuHeight = document.querySelector<HTMLElement>('.header-container').offsetHeight;
    const rafflesNavHeight = this.navInnerRef.nativeElement['offsetHeight'];

    const topCheckpoint = mainMenuHeight + rafflesNavHeight;

    for (const item of this.items) {
      const rect = document.getElementById(item.idAttr).getBoundingClientRect();

      if (rect.top + rect.height - 3 >= topCheckpoint) {
        if (item != this.activeItem) {
          this.activeItem = item;
          this.scrollNavItemIntoView(item);
        }

        break;
      }
    }
  }

  keepNavigationInViewport(): void {
    if (!this.navPositionRef?.nativeElement) {
      return;
    }

    const rect = this.navPositionRef.nativeElement.getBoundingClientRect();
    const navScrolledRelative = rect.top;

    const mainMenuHeight = document.querySelector<HTMLElement>('.header-container').offsetHeight;
    const compsWrapper = document.getElementById('competitions-wrapper');

    if (!compsWrapper) { // if it's deteached from DOMTree
      return;
    }

    const compsWrapperHeight = compsWrapper['offsetHeight'];
    const compsWrapperRect = compsWrapper.getBoundingClientRect();
    const rafflesNavHeight = this.navInnerRef.nativeElement['offsetHeight'];

    this.isNavShort = (mainMenuHeight > navScrolledRelative);

    if (-1 * compsWrapperRect.top > compsWrapperHeight - mainMenuHeight - rafflesNavHeight) {
      this.navAbsoluteTop = compsWrapperHeight - rafflesNavHeight;
      this.navCssPosition = 'absolute';
      this.navFixed['raffles-nav__fixed'] = false;
    } else if (mainMenuHeight > navScrolledRelative) {
      this.navAbsoluteTop = mainMenuHeight;
      this.navCssPosition = 'fixed';
      this.navFixed['raffles-nav__fixed'] = true;
    } else {
      this.navAbsoluteTop = 0;
      this.navCssPosition = 'absolute';
      this.navFixed['raffles-nav__fixed'] = false;
    }
  }

  installNavScrollListener(): void {
    fromEvent(window, 'mousemove').
      pipe(
        takeUntil(this._alive$),
      ).subscribe((event) => {
        this.doScrollingNav(event);
      });

    fromEvent(window, 'touchmove').
      pipe(
        takeUntil(this._alive$),
      ).subscribe((event) => {
        this.doScrollingNav(event);
      });

    fromEvent(window, 'mouseup').
      pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        this.stopScrollingNav();
      });

    fromEvent(window, 'touchend').
      pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        this.stopScrollingNav();
      });

    fromEvent(window, 'touchcancel').
      pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        this.stopScrollingNav();
      });

    // fromEvent(window, 'mouseout').
    // pipe(
    //   takeUntil(this._alive$),
    // ).subscribe((event) => {
    //   this.stopScrollingNav();
    // });
  }

  startScrollingNav(event): boolean {
    this.lastCursorNavPosition = this.getCursorRelativePositionFromEvent(event);
    this.lastCursorDownNavPosition = this.lastCursorNavPosition;

    return false;
  }

  doScrollingNav(event: Event): void {
    if (this.lastCursorNavPosition < 0) {
      return;
    }

    const cursorPosition = this.getCursorRelativePositionFromEvent(event);
    const scrollRelative = cursorPosition - this.lastCursorNavPosition;
    this.lastCursorNavPosition = cursorPosition;
    this.navInnerRef.nativeElement.scrollLeft = this.navInnerRef.nativeElement.scrollLeft - scrollRelative;
  }

  stopScrollingNav(): boolean {
    if (this.lastCursorNavPosition < 0) {
      return false;
    }

    this.lastCursorNavPosition = -1;

    setTimeout(() => {
      this.lastCursorDownNavPosition = -1;
    }, 5);

    return false;
  }

  getNavElemByItem(item: ItemTab): string {
    return 'nav-item-' + item.idAttr;
  }

  private getCursorRelativePositionFromEvent(event): number {
    if (event.touches && event.touches.length == 1) {
      return event.touches[0].clientX;
    }

    if (event.changedTouches && event.changedTouches.length == 1) {
      return event.changedTouches[0].clientX;
    }

    return event.clientX;
  }
}

export class ItemTab {
  idAttr: string;
  name: string;
  fullName: string;
}
