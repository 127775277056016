import { Pipe, PipeTransform } from '@angular/core';
import { DateParserService } from '@core/services/date-parser/date-parser.service';

@Pipe({
  name: 'unifyDate',
  standalone: true
})
export class DateUnifierPipe implements PipeTransform {
  // @TODO: what is the type must to be there ?
  transform(dateInput: any): Date | null {
    return DateParserService.parse(dateInput);
  }
}
