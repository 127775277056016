import { Injectable } from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserTrackingService {
  @Select(AuthState.user) user$: Observable<User>;

  appendHeadScripts() {
    this.user$.subscribe((user) => {
      if (user) {
        this.createScript1(user.id, user.email, user.acquisition, user.acquisition_campaign);
      } else {
        this.deleteScript1();
      }
    });

    if ((<any>window).ENVIRONMENT_PARAMETERS.live_agent_enabled) {
      this.createScript4();
    }
  }

  createScript1(globalUserId, globalUserEmail, globalAcquisitionChannel, globalAcquisitionCampaignChannel) {
    window['globalUserId'] = globalUserId;
    window['globalUserEmail'] = globalUserEmail;
    window['globalAcquisitionChannel'] = !globalAcquisitionChannel ? null : JSON.stringify(globalAcquisitionChannel);
    window['globalAcquisitionCampaignChannel'] = !globalAcquisitionCampaignChannel ? null : JSON.stringify(globalAcquisitionCampaignChannel);
  }

  deleteScript1() {
    window['globalUserId'] = null;
    window['globalUserEmail'] = null;
    window['globalAcquisitionChannel'] = null;
    window['globalAcquisitionCampaignChannel'] = null;
  }

  createScript4() {
    const script_4 = document.createElement('script');
    script_4.innerHTML = `
      (function(d, src, c) {
        var t=d.scripts[d.scripts.length - 1],
        s=d.createElement('script');
        s.id='la_x2s6df8d';
        s.async=true;
        s.src=src;
        s.onload=s.onreadystatechange=function() {
          var rs=this.readyState;
          if(rs&&(rs!='complete')&&(rs!='loaded')) {
            return;
          }c(this);
        };
        t.parentElement.insertBefore(s,t.nextSibling);
      })(document, 'https://support.odurn.com/scripts/track.js', function(e){
        LiveAgent.createButton('hwevk6w1', e);
      });`;
    document.head.appendChild(script_4);
  }
}
