import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { TrustpilotComponent } from '@components/trustpilot/trustpilot.component';
import { PaymentMethodsComponent } from '@project/shared/template/components/payment-methods/payment-methods.component';
import { TrustpilotConfig, TrustpilotTemplate, TrustpilotTheme } from '@core/models/trustpilot.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterLink,
    RouterModule,
    PaymentMethodsComponent,
    NgIf,
    TrustpilotComponent
  ]
})
export class FooterComponent {
  public isNativeAppVisible = false;
  public isIos = false;
  public isAndroid = false;

  trustpilotConfig: TrustpilotConfig = {
    template: TrustpilotTemplate.Free,
    theme: TrustpilotTheme.Light
  };

  constructor(
  ) {
    this.isNativeAppVisible = GonativeService.isGonative();
    this.isIos = GonativeService.isIos();
    this.isAndroid = GonativeService.isAndroid();
  }
  

  

}
