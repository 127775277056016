import { Injectable } from '@angular/core';
import { PageTplDataModel, PageTplDataService } from '@core/services/page-tpl-data/page-tpl-data.service';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import { Observable } from 'rxjs';
import { SettingsState } from '@store/settings/settings.state';
import { SettingsModel } from '@core/models/settings.model';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { VersionControlService } from '@core/services/version-checker/version-control.service';
import { Meta } from '@angular/platform-browser';
import { UserTrackingService } from '@core/services/user-tracking/user-tracking.service';
import { HttpClient } from '@angular/common/http';
import {
  UserDrawTicketCountersService
} from '@core/services/user-draw-ticket-counters/user-draw-ticket-counters.service';
import { CartService } from '@core/services/cart/cart.service';
import { SettingsService } from '@core/services/settings/settings.service';
import { UserengageService } from '@core/services/userengage/userengage.service';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { AppsflyerService } from '@core/services/appsflyer/appsflyer.service';
import { MessagePopupService } from '@core/services/message-popup/message-popup.service';
import { CountriesService } from '@core/services/countries/countries.service';
import { appData, ProjectDataService } from '@core/services/project-data/project-data.service';
import { ClearAuthData, RefreshUser, SetSession, SetUser } from '@store/auth/auth.actions';
import { ClearCartErrors } from '@store/cart/cart.actions';
import { API_LIST } from '@core/services/web-api/api-list';
import { SessionModelResponse } from '@core/models/session-model-response.model';
import { ProjectDataModel } from '../../../../../projects/project-data.default';
import { AbTestsService } from '@core/services/ab-tests/ab-tests.service';
import { GeoData } from '@core/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public pageTplData: PageTplDataModel;
  firstTimeLaunched = false;
  subWebsite = 'website-p7d';
  public websiteStatus = '1';
  public websiteDisabledMessage = '';

  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(SettingsState.settings) settings$: Observable<SettingsModel>;

  constructor(
    public pageTplDataService: PageTplDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private versionControlService: VersionControlService,
    private meta: Meta,
    private window: Window,
    private userTracking: UserTrackingService,
    private store: Store,
    private httpClient: HttpClient,
    private ticketCounters: UserDrawTicketCountersService,
    private cartService: CartService,
    private settingsService: SettingsService,
    private userEngageService: UserengageService,
    private gonative: GonativeService,
    private appsFlyer: AppsflyerService,
    public messagePopupService: MessagePopupService,
    private countriesService: CountriesService,
    private projectDataService: ProjectDataService,
    private abTestsService: AbTestsService
  ) {

  }

  init(options: { projectData: ProjectDataModel }): void {
    this.projectDataService.setData(options.projectData);
    this.userTracking.appendHeadScripts();
    this.appsFlyer.initEventRecorderForGtm();
    this.purgeServiceWorker();
    this.countriesService.detectGeoCountryIfExpired();

    this.countriesService.visitorCountry$.subscribe((geoData: GeoData) => {
      if (geoData) {
        if (geoData.restricted) {
          this.router.navigate(['/restricted-country']);
        }
      }
    });

    this.abTestsService.init();

    this.settings$.subscribe((settings: SettingsModel) => {
      if (!settings) {
        return;
      }

      this.websiteStatus = String(settings.website_status);
      this.websiteDisabledMessage = settings.website_disabled_message;
    });

    this.window.addEventListener('message', (event: any) => {
      if (event.data !== 'is_iframe') {
        return;
      }
      this.updateAllLinksToParent();
    });

    this.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch(new RefreshUser());
      } else {
        setTimeout(() => {
          this.store.dispatch(new SetUser(null));
        }, 500);
      }
    });

    this.pageTplData = this.pageTplDataService.pageTplData;

    this.userEngageService.installPageListener();
    this.gonative.installIfNative();

    // find active route data
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        appData.navigationTrigger = event.navigationTrigger;
      }

      if (event instanceof NavigationEnd) {
        this.firstTimeLaunched = true;

        const routeSnapshot: ActivatedRouteSnapshot[] = [];
        const activeRoutes: ActivatedRoute[] = this.activatedRoute.children;

        activeRoutes.forEach((route: ActivatedRoute) => {
          let activeRoute: ActivatedRoute = route;
          while (activeRoute.firstChild) {
            activeRoute = activeRoute.firstChild;
          }
          routeSnapshot.push(activeRoute.snapshot);
        });
        // save data for later use in other components
        this.pageTplDataService.setPageTitle((<any>routeSnapshot[0].data).pageTitle);
        this.pageTplDataService.setPageClass((<any>routeSnapshot[0].data).pageClass);
        this.subWebsite = this.getSubWebsiteFromActiveRouteSnapshot(routeSnapshot[0]);
        this.updateMetaTags(this.subWebsite);
        this.store.dispatch(new ClearCartErrors());
        this.countriesService.detectGeoCountryIfExpired();

        this.messagePopupService.clearMessages();
        this.handleQueryParamSessions(routeSnapshot);
        this.window.scrollTo(0, 0);
        appData.navigationTrigger = '';
      }

      this.versionControlService.reloadPageOnceChunkLoadingFailed(event);
    });
  }

  handleQueryParamSessions(routeSnapshot: ActivatedRouteSnapshot[]) {
    let queryParamsWithoutSession = { ...routeSnapshot[0].queryParams };
    if (queryParamsWithoutSession.session_token) delete queryParamsWithoutSession.session_token;
    if (queryParamsWithoutSession.user_session) delete queryParamsWithoutSession.user_session;
    if (queryParamsWithoutSession.redirect_url) delete queryParamsWithoutSession.redirect_url;

    // if external login requested, init user data
    if (routeSnapshot[0].queryParams['user_session']) {
      this.store.dispatch(new ClearAuthData());

      this.httpClient.post(API_LIST.loginExternal(routeSnapshot[0].queryParams['user_session']), {}).subscribe((session: SessionModelResponse) => {
        this.store.dispatch(new SetSession(session));
        this.ticketCounters.clearCounters();

        if (session.user.ab_test_seed) {
          this.abTestsService.setSeed(session.user.ab_test_seed);
        }

        this.router.navigate([this.getTargetUrl(routeSnapshot[0].queryParams['redirect_url'])], {
          queryParams: queryParamsWithoutSession,
        });
      }, (err) => {
        queryParamsWithoutSession.redirect_url = this.getTargetUrl(routeSnapshot[0].queryParams['redirect_url']);

        this.router.navigate(['/my-account'], {
          queryParams: queryParamsWithoutSession,
        });
      });
    }

    // if active session is found, init user data
    if (routeSnapshot[0].queryParams['session_token']) {
      this.store.dispatch(new ClearAuthData());

      this.httpClient.get(API_LIST.getSessionData(routeSnapshot[0].queryParams['session_token'])).subscribe((session: SessionModelResponse) => {
        this.store.dispatch(new SetSession(session));
        this.ticketCounters.clearCounters();

        this.router.navigate([this.getTargetUrl(routeSnapshot[0].queryParams['redirect_url'])], {
          queryParams: queryParamsWithoutSession,
        });
      }, (err) => {
        queryParamsWithoutSession.redirect_url = this.getTargetUrl(routeSnapshot[0].queryParams['redirect_url']);

        this.router.navigate(['/my-account'], {
          queryParams: queryParamsWithoutSession,
        });
      });
    }
  }

  updateAllLinksToParent() {
    let links = (<any>document).getElementsByTagName('a');

    for (let link of links) {
      if (!link.target && link.getAttribute('href')) {
        link.target = '_top';

        // to remove all listeners, including angular routerLink
        let clonedLink = link.cloneNode(true);
        link.parentNode.replaceChild(clonedLink, link);
      }
    }
  }

  getSubWebsiteFromActiveRouteSnapshot(routeSnapshot: ActivatedRouteSnapshot | { data: any, parent: any }) {
    if (routeSnapshot.data.website) {
      return this.getSubWebsite(routeSnapshot.data.website);
    }

    if (routeSnapshot.parent) {
      return this.getSubWebsiteFromActiveRouteSnapshot(routeSnapshot.parent);
    }

    return this.getSubWebsite(null);
  }

  getSubWebsite(website) {
    if (website) {
      return 'website-' + website;
    } else {
      return 'website-p7d';
    }
  }

  updateMetaTags(websiteString: string) {
    this.meta.updateTag({
      property: 'fb:app_id',
      content: (<any>this.window).ENVIRONMENT_PARAMETERS.fb_app_id,
    });
  }

  getTargetUrl(url) {
    if (!url) {
      url = this.window.location.pathname;
    } else {
      url = decodeURIComponent(url);
    }

    return url;
  }

  purgeServiceWorker() {

    if ('caches' in window) {
      caches.keys()
        .then(function (keyList) {
          return Promise.all(keyList.map(function (key) {
            return caches.delete(key);
          }));
        })
        .catch(() => {
        });
    }

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
    }
  }
}
